import { handleActions } from 'redux-actions';
import {
	SET_UTM_PARAMS,
	SET_COUPON_DATA,
	SET_INVALID_COUPON,
} from '../actionTypes';

const initialState = {
	params: {},
	couponData: {},
	couponInvalid: false,
};

const analyticsReducer = handleActions(
	{
		[SET_UTM_PARAMS]: (state, { payload }) => ({
			...state,
			params: payload,
		}),
		[SET_COUPON_DATA]: (state, { payload }) => ({
			...state,
			couponData: payload,
		}),
		[SET_INVALID_COUPON]: (state, { payload }) => ({
			...state,
			couponInvalid: payload,
		}),
	},
	initialState
);

export default analyticsReducer;
