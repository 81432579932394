import {
	ADD_DELIVERY_ESTIMATE_OPTION,
	FILTER_COMPARE_RESULTS,
	GET_BIDS,
	SET_ACTIVE_BID,
	SET_IS_CHANGE_MOVER,
	GET_PRICING_BREAKDOWN,
	INIT_COMPARE_PAGE,
	SELECT_MOVER,
	SELECT_MOVER_FROM_HOMEPAGE,
	UNSELECT_MOVER_FROM_HOMEPAGE,
	SET_BIDS,
	SET_COMPARE_FILTER,
	SET_COMPARE_LOADING,
	SET_FILTERED_RESULTS,
	SET_PRICING_BREAKDOWN,
	MODAL_LOADING,
} from '../actionTypes';

export const initCompare = mpid => ({
	type: INIT_COMPARE_PAGE,
	payload: mpid,
});

export const setCompareLoading = bool => ({
	type: SET_COMPARE_LOADING,
	payload: bool,
});

export const getBids = () => ({
	type: GET_BIDS,
});

export const setCompareFilter = selectedOption => ({
	type: SET_COMPARE_FILTER,
	payload: selectedOption,
});

export const setBids = bidData => ({
	type: SET_BIDS,
	payload: bidData,
});

export const setActiveBid = bidId => ({
	type: SET_ACTIVE_BID,
	payload: bidId,
});

export const setIsChangeMover = changeMover => ({
	type: SET_IS_CHANGE_MOVER,
	payload: changeMover,
});

export const addDeliveryEstimateOption = bool => ({
	type: ADD_DELIVERY_ESTIMATE_OPTION,
	payload: bool,
});

export const setFilteredResults = filtered => ({
	type: SET_FILTERED_RESULTS,
	payload: filtered,
});

export const filterCompareResults = filter => ({
	type: FILTER_COMPARE_RESULTS,
	payload: filter,
});

export const selectMover = moverId => ({
	type: SELECT_MOVER,
	payload: moverId,
});

export const selectMoverFromHomePage = moverId => ({
	type: SELECT_MOVER_FROM_HOMEPAGE,
	payload: moverId,
});

export const unselectMoverFromHomePage = moverId => ({
	type: UNSELECT_MOVER_FROM_HOMEPAGE,
	payload: moverId,
});

export const getPricingBreakdown = moverId => ({
	type: GET_PRICING_BREAKDOWN,
	payload: moverId,
});

export const setBidPricingBreakdown = pricing => ({
	type: SET_PRICING_BREAKDOWN,
	payload: pricing,
});

export const setModalLoading = bool => ({
	type: MODAL_LOADING,
	payload: bool,
});
