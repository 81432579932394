import { useState, useEffect, useRef, useCallback } from 'react';
import TagManager from 'react-gtm-module';

// Credit to Dan Abrahmov @ https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
	const savedCallback = useRef();
	useEffect(() => {
		savedCallback.current = callback;
	});
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		let id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay]);
}

// Credit to Gabe Ragland @ https://usehooks.com
export const useMedia = (queries, values, defaultValue) => {
	const mediaQueryLists = queries.map(q => window.matchMedia(q));
	const getValue = useCallback(() => {
		const index = mediaQueryLists.findIndex(mql => mql.matches);
		return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
	}, [mediaQueryLists, defaultValue, values]);
	const [value, setValue] = useState(getValue());
	useEffect(() => {
		const handler = () => setValue(getValue);
		mediaQueryLists.forEach(mql => mql.addListener(handler));
		return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
	}, [mediaQueryLists, setValue, getValue]);
	return value;
};

// Credit to Siddharth Kshetrapal @ https://github.com/siddharthkp/use-timeout
export const useTimeout = (callback, delay) => {
	const savedCallback = useRef();
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setTimeout(tick, delay);
			return () => clearTimeout(id);
		}
	}, [delay]);
};

export const useForceUpdate = () => useState()[1];

const getSize = () => ({
	innerHeight: window.innerHeight,
	innerWidth: window.innerWidth,
	outerHeight: window.outerHeight,
	outerWidth: window.outerWidth,
});

export const useWindowSize = () => {
	let [windowSize, setWindowSize] = useState(getSize());

	function handleResize() {
		setWindowSize(getSize());
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowSize;
};

//credit to Gabe Ragland @ https://usehooks.com/useScript/

let cachedScripts = [];
export const useScript = src => {
	// Keeping track of script loaded and error state
	const [state, setState] = useState({
		loaded: false,
		error: false,
	});

	useEffect(
		() => {
			// If cachedScripts array already includes src that means another instance ...
			// ... of this hook already loaded this script, so no need to load again.
			if (cachedScripts.includes(src)) {
				setState({
					loaded: true,
					error: false,
				});
			} else {
				cachedScripts.push(src);

				// Create script
				let script = document.createElement('script');
				script.src = src;
				script.async = true;

				// Script event listener callbacks for load and error
				const onScriptLoad = () => {
					setState({
						loaded: true,
						error: false,
					});
				};

				const onScriptError = () => {
					// Remove from cachedScripts we can try loading again
					const index = cachedScripts.indexOf(src);
					if (index >= 0) cachedScripts.splice(index, 1);
					script.remove();
					setState({
						loaded: true,
						error: true,
					});
				};

				script.addEventListener('load', onScriptLoad);
				script.addEventListener('error', onScriptError);

				// Add script to document body
				document.body.appendChild(script);

				// Remove event listeners on cleanup
				return () => {
					script.removeEventListener('load', onScriptLoad);
					script.removeEventListener('error', onScriptError);
				};
			}
		},
		[src] // Only re-run effect if script src changes
	);

	return [state.loaded, state.error];
};
export const useMetaNoFollow = () => {
	useEffect(() => {
		const stage = process.env.REACT_APP_STAGE;
		const isStaging =
			window.location.href.indexOf('demo') === -1 &&
			window.location.href.indexOf('canary') === -1 &&
			window.location.href.indexOf('sandbox') === -1;
		if (
			stage === 'prod' &&
			window.location.pathname === '/' &&
			window.location.host.indexOf('www') === -1 &&
			isStaging
		) {
			window.location.href = 'https://www.unpakt.com';
		}

		if (
			stage === 'dev' ||
			(window.location.href.indexOf('test') > -1 &&
				window.location.href.indexOf('/movers/') === -1) ||
			window.location.href.indexOf('demo') > -1 ||
			(window.location.href.indexOf('exp') > -1 &&
				window.location.href.indexOf('/movers/') === -1) ||
			(window.location.href.indexOf('api') > -1 &&
				window.location.href.indexOf('/movers/') === -1 &&
				window.location.href.indexOf('/moving-companies/') === -1) ||
			window.location.href.indexOf('/moving/') > -1 ||
			window.location.href.indexOf('netdna') > -1 ||
			window.location.href.indexOf('concierge') > -1 ||
			window.location.href.indexOf('/hourly') > 1 ||
			window.location.host.indexOf('mobile.') === 0
		) {
			const meta = document.createElement('meta');
			meta.name = 'robots';
			meta.content = 'nofollow, noindex';
			document.getElementsByTagName('head')[0].appendChild(meta);
		}
	}, []);
};

export const useTagManager = () => {
	useEffect(() => {
		// check in tag manager if this works, also check if a dataLayer object needs to be defined
		const tagManagerArgs = {
			gtmId: 'GTM-PF2MX77',
		};

		TagManager.initialize(tagManagerArgs);
	}, []);
};

export const useIsFirstRender = () => {
	const isFirst = useRef(true);

	if (isFirst.current) {
		isFirst.current = false;

		return true;
	}

	return isFirst.current;
};
