import {
	SET_SUGGESTIONS,
	SELECT_SUGGESTION,
	SET_SCRIPT_LOADING,
	SET_SELECTED_DNA_TAB,
	SET_PPC_NYC_SELECTED_DNA_TAB,
	SET_PREV_PICK_UP_ADDRESS,
	SET_PREV_DROP_OFF_ADDRESS,
	SET_CURRENT_PICK_UP_ADDRESS,
	SET_CURRENT_ADDRESS,
	SET_CURRENT_DROP_OFF_ADDRESS,
	SET_DUMMY_PAYLOAD_FOR_MOVE_PLAN,
} from '../actionTypes';
import { handleActions } from 'redux-actions';

const initialState = {
	suggestions: [],
	selected: {
		pick_up: {},
		drop_off: {},
	},
	scriptLoading: false,
	selectedDNAtab: 'hourly',
	selectedPpcNycDNAtab: 'regular',
	prevPickUpAddress: null,
	prevDropOffAddress: null,
	currentAddress: null,
	currentPickUpAddress: null,
	currentDropOffAddress: null,
	dummyPayloadForMovePlan: null,
};

const commonReducer = handleActions(
	{
		[SET_SUGGESTIONS]: (state, { payload }) => ({
			...state,
			suggestions: payload,
		}),
		[SELECT_SUGGESTION]: (state, { payload }) => ({
			...state,
			selected: {
				...state.selected,
				[payload.key]: payload.suggestion,
			},
		}),
		[SET_SCRIPT_LOADING]: (state, { payload }) => ({
			...state,
			scriptLoading: payload,
		}),
		[SET_SELECTED_DNA_TAB]: (state, { payload }) => ({
			...state,
			selectedDNAtab: payload,
		}),
		[SET_PPC_NYC_SELECTED_DNA_TAB]: (state, { payload }) => ({
			...state,
			selectedPpcNycDNAtab: payload,
		}),
		[SET_PREV_PICK_UP_ADDRESS]: (state, { payload }) => ({
			...state,
			prevPickUpAddress: payload,
		}),
		[SET_PREV_DROP_OFF_ADDRESS]: (state, { payload }) => ({
			...state,
			prevDropOffAddress: payload,
		}),
		[SET_CURRENT_PICK_UP_ADDRESS]: (state, { payload }) => ({
			...state,
			currentPickUpAddress: payload,
		}),
		[SET_CURRENT_ADDRESS]: (state, { payload }) => ({
			...state,
			currentAddress: payload,
		}),
		[SET_CURRENT_DROP_OFF_ADDRESS]: (state, { payload }) => ({
			...state,
			currentDropOffAddress: payload,
		}),
		[SET_DUMMY_PAYLOAD_FOR_MOVE_PLAN]: (state, { payload }) => ({
			...state,
			dummyPayloadForMovePlan: payload,
		}),
	},
	initialState
);

export default commonReducer;
