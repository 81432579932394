import handleActions from 'redux-actions/es/handleActions';
import { SET_JOB_DECLINE_INFO, SET_MOVER_LOADING } from '../actionTypes';

const initialState = {
	job: {
		mover_declined_reason_id: undefined,
		mover_declined_other_reason: undefined,
		mover_declined_comment: undefined,
	},
	loading: false,
};

const moverReducer = handleActions(
	{
		[SET_JOB_DECLINE_INFO]: (state, { payload }) => ({
			...state,
			job: {
				mover_declined_reason_id: payload.mover_declined_reason_id,
				mover_declined_other_reason: payload.mover_declined_other_reason,
				mover_declined_comment: payload.mover_declined_comment,
			},
		}),
		[SET_MOVER_LOADING]: (state, { payload }) => ({
			...state,
			loading: payload,
		}),
	},
	initialState
);

export default moverReducer;
