import { handleActions } from 'redux-actions';
import { SET_S3_UPLOAD_URL } from '../actionTypes';

const initialState = {
	exampleAddressURLs: {
		pick_up: false,
		drop_off: false,
		extra_pick_up: false,
		extra_drop_off: false,
	},
	completedAddressURLs: {
		pick_up: false,
		drop_off: false,
		extra_pick_up: false,
		extra_drop_off: false,
	},
};

const s3Reducer = handleActions(
	{
		[SET_S3_UPLOAD_URL]: (state, { payload }) => {
			return {
				...state,
				[`${!!payload.completed ? 'completed' : 'example'}AddressURLs`]: {
					...state[
						`${!!payload.completed ? 'completed' : 'example'}AddressURLs`
					],
					[payload.addressKey]: payload.url,
				},
			};
		},
	},
	initialState
);

export default s3Reducer;
