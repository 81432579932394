import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { toggleLeftMenu } from '../../../redux/actions/ui/mobile';
import { connect } from 'react-redux';
import { isBidsPage as isBids } from '../../../helpers/isBidsPage';

const AsyncMobileNav = lazy(() => import('./MobileNav'));
const AsyncMobileDimmer = lazy(() => import('./MobileDimmer'));

const mapStateToProps = ({ ui, funnel }) => ({
	leftMenuVisible: ui.mobile.leftMenuVisible,
	currentStep: funnel.currentStep,
});

const MobileHeader = props => {
	const [showNav, setShowNav] = useState(false);
	const [showDimmer, setShowDimmer] = useState(false);
	const isBidsPage = isBids();

	useEffect(
		() => setShowDimmer(props.leftMenuVisible),
		[props.leftMenuVisible]
	);
	useEffect(() => setShowDimmer(showNav), [showNav]);

	const handleToggle = () => setShowNav(!showNav);
	// const handleLeftSidebarToggle = () => props.toggleLeftMenu();
	const handleDimmerClick = () => {
		if (props.leftMenuVisible) props.toggleLeftMenu();
		else setShowNav(false);
		setShowDimmer(false);
	};
	return (
		<header id="header-container" className="header header-container ">
			<Link
				to={isBidsPage ? '/bids' : '/'}
				className="logo-container mobile d-inline-flex float-left "
				rel="canonical">
				<div className="logo-with-text">
					<svg
						className="logo"
						xmlns="http://www.w3.org/2000/svg"
						data-name="Layer 1"
						viewBox="0 0 1000 555">
						<defs>
							<style>color: #fff;</style>
						</defs>
						<title>Unpakt_R_white_v1</title>
						<path
							className="logo"
							d="M685.2 348.7h38.6V152h-38.6v196.7zm-361-133.4a60.2 60.2 0 0 0-42.7-18.3c-15.7 0-33.5 9.2-43.2 21v-16.3h-36.2v147h38.6V282c0-11.6-.6-27.5 5.8-37.6 4.9-7.9 15.3-13 25-13 9.2 0 18.4 4.5 23.5 12.2 6.2 9.8 6 24.3 6 35.7v69.4h38.7v-76.3c0-18.8-.2-37-11.8-52.9a28.1 28.1 0 0 0-3.7-4.2zm111.6-19c-20.2 0-35.6 7.1-45.2 23.7v-18.2h-36.3v201.4h38.6v-71.9c11.6 14.6 26.6 21.3 42.8 21.1 26.2-.4 43.7-9.5 56.6-24.6 11.9-14 18.6-33.1 18.6-53.5s-5.4-37.3-19.1-52.7c-15.1-17.2-32.2-25.4-56-25.4zM432 317.9c-25.1 0-41.5-19.3-41.5-43.6s16.5-43.7 41.6-43.7c26.4 0 43.1 19.3 43.1 45-.1 23.7-17.8 42.3-43.2 42.3zm204.9-98c-9.7-16.6-25-23.7-45.2-23.7-23.8 0-41 8.2-56.1 25.4-13.8 15.4-19.2 32.3-19.2 52.7s6.8 39.4 18.6 53.5c13 15.1 30.4 24.2 56.6 24.6 16.3.3 31.8-6.5 45.2-21.1v17.4H673V201.8h-36.2V220zm-41.5 98c-25.4 0-43.1-18.6-43.1-42.3 0-25.7 16.7-45 43.1-45 25.1 0 41.6 19.4 41.6 43.7s-16.5 43.6-41.6 43.6zm225-116.2H777l-45.9 63.1V282l45.9 66.7h45.8l-57.4-74.4zm45.9-49.7h-38.6v196.7h38.6V237.3h31.4v-35.5h-31.4V152zM149.4 274c0 9-.3 21.4-4.6 29.9-4.7 9.3-14 14.7-24.7 15-10.7-.3-20-5.7-24.7-15-4.5-8.4-4.7-20.9-4.7-29.9l.3-72.2H52.4v75.8c0 18.8 3.2 41.4 16.7 55.5 12.4 13.8 29.4 20.4 47.2 20.4h7.4c17.8 0 34.8-6.6 47.2-20.4 13.5-14 16.7-36.4 16.7-55.2v-76.1H149zm790.9-107.1h-5.1v10.2H932v-25.7h10.3c4.7 0 8 3 8 7.7a7.1 7.1 0 0 1-6.7 7.5l6.9 10.5h-3.8zm1.7-12.7h-6.7v9.8h6.7a4.9 4.9 0 1 0 0-9.8z"
						/>
						<path
							className="logo"
							d="M941.2 190.3a26 26 0 1 1 26-26 26.1 26.1 0 0 1-26 26zm0-48.9a22.8 22.8 0 1 0 22.8 22.8 22.9 22.9 0 0 0-22.8-22.8z"
						/>
					</svg>
					<span>The Moving App</span>
				</div>
			</Link>
			<Suspense fallback={''}>
				<AsyncMobileNav showNav={showNav} toggle={handleToggle} />
			</Suspense>
			{showDimmer && (
				<>
					<Suspense fallback={''}>
						<AsyncMobileDimmer handleToggle={handleDimmerClick} />
					</Suspense>
				</>
			)}
			{/* { (props.currentStep === 'INVENTORY' || props.currentStep === 'COMPARE') && 
        <div className={`plus-sign`}  onClick={handleLeftSidebarToggle}>
          <div className={`${props.leftMenuVisible ? 'rotated' : ''} plus-sign-interior`}>+</div>
        </div>
      } */}
			<div
				className={`hamburger ${showNav && 'menu-open'}`}
				onClick={handleToggle}>
				<span className="bar" />
			</div>
			<style jsx>{`
				.header {
					width: 100%;
					position: fixed;
					left: 0;
					top: 0;
					height: 70px;
					min-height: 70px;
					line-height: 70px;
					z-index: 1000;
					background: #1a3749;
					line-height: 70px;
				}
				.logo {
					fill: white;
					height: 70px;
					margin-right: 10px;
				}
				:global(.logo-container.mobile) {
					display: block;
					height: 100%;
					margin-right: 20px;
					padding-left: 25px;
				}
				.hamburger {
					position: absolute;
					display: block;
					right: 25px;
					top: 1px;
					width: 35px;
					padding: 0 15px;
					height: 70px;
					transition: -webkit-transform 0.2s;
					transition: transform 0.2s;
					transition: transform 0.2s, -webkit-transform 0.2s;
					cursor: pointer;
				}
				.bar {
					top: 31px;
					position: absolute;
					top: 44%;
					width: 35px;
					height: 4px;
					background: white;
					transition: background 0s 0.2s;
				}
				.bar::before {
					top: -9px;
					transition: top 0.2s 0.2s, -webkit-transform 0.2s 0s;
					transition: top 0.2s 0.2s, transform 0.2s 0s;
					transition: top 0.2s 0.2s, transform 0.2s 0s,
						-webkit-transform 0.2s 0s;
					position: absolute;
					display: block;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: white;
					content: '';
				}
				.bar:after {
					bottom: -9px;
					transition: bottom 0.2s 0.2s, -webkit-transform 0.2s 0s;
					transition: bottom 0.2s 0.2s, transform 0.2s 0s;
					transition: bottom 0.2s 0.2s, transform 0.2s 0s,
						-webkit-transform 0.2s 0s;
					position: absolute;
					display: block;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: white;
					content: '';
				}
				.menu-open {
					background: 0 0;
					transition: background 0s 0.2s;
					-webkit-transform: scale(0.7);
					-ms-transform: scale(0.7);
					transform: scale(0.7);
				}
				.menu-open span::before {
					top: 0;
					-webkit-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
					transition: top 0.2s 0s, -webkit-transform 0.2s 0.2s;
					transition: top 0.2s 0s, transform 0.2s 0.2s;
					transition: top 0.2s 0s, transform 0.2s 0.2s,
						-webkit-transform 0.2s 0.2s;
				}
				.menu-open span:after {
					bottom: 0;
					-webkit-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					transform: rotate(-45deg);
					transition: bottom 0.2s 0s, -webkit-transform 0.2s 0.2s;
					transition: bottom 0.2s 0s, transform 0.2s 0.2s;
					transition: bottom 0.2s 0s, transform 0.2s 0.2s,
						-webkit-transform 0.2s 0.2s;
				}
				.menu-open span {
					background: 0 0;
					transition: background 0s 0.2s;
				}
				.logo-with-text {
					display: flex;
					align-items: flex-end;
					font-size: 0.75rem;
					color: #ffffffe8;
					justify-content: flex-start;
				}
				.logo-with-text span {
					line-height: 5;
					margin: 0px 0px 0px -1.25rem;
				}
			`}</style>
		</header>
	);
};

export default connect(mapStateToProps, { toggleLeftMenu })(MobileHeader);
