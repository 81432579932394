import { OPEN_MODAL } from '../actionTypes';
import { fetchInventory } from '../actions/inventory';
import { LOCATION_CHANGE } from 'connected-react-router';
import { closeAllModals } from '../actions/modals';

const modalsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type === OPEN_MODAL) {
			if (action.payload.modal.name === 'specialHandling') {
				dispatch(fetchInventory(getState().movePlan.currentMPID));
			}
		}
		if (action.type === LOCATION_CHANGE) {
			dispatch(closeAllModals());
		}
	};

export default modalsMiddleware;
