import { handleActions } from 'redux-actions';

const initialState = {
	resizeFunnel: false,
	hideFooter: false,
	hideMovePlan: false,
	hideWizard: false,
	viewLoaded: false,
};

const movingReducer = handleActions({}, initialState);

export default movingReducer;
