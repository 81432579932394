import { INIT_CONGRATS_PAGE } from '../actionTypes';
import { getCurrentMoveplan, setCurrentMoveplanID } from '../actions/movePlan';
import { setCurrentStep } from '../actions/funnel';
import { fetchInventory, setInventoryLoading } from '../actions/inventory';
import { loadTwitter, loadFacebook } from '../actions/social';

const congratsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		if (action.type === INIT_CONGRATS_PAGE) {
			dispatch(setInventoryLoading(true));
			dispatch(setCurrentStep('CONGRATS'));
			dispatch(loadFacebook());
			dispatch(loadTwitter());
			if (getState().movePlan.currentMPID !== action.payload.movePlanId) {
				dispatch(setCurrentMoveplanID(action.payload.movePlanId));
			}
			dispatch(getCurrentMoveplan());
			dispatch(fetchInventory(action.payload.movePlanId));
		}
	};

export default congratsMiddleware;
