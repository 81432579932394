import React from 'react';
import { Link } from 'react-router-dom';
import { isBidsPage as isBids } from '../../../helpers/isBidsPage';

const SiteLinks = props => {
	const { sender } = props;
	const isBidsPage = isBids();
	return (
		<div className="container">
			<ul
				className={`footer-site-links row ${
					sender === 'move-plan-footer'
						? 'justify-between footer-menu'
						: 'footer-menu row'
				} ${isBidsPage ? 'justify-content-start' : ''}`}>
				<li className="menu-column left col-12 col-md-2">
					<Link
						className="menu-title text-2xl"
						to={isBidsPage ? '/what-is-unpakt-bids/' : '/what-is-unpakt/'}>
						About Us
					</Link>
					<ul className="footer-about-menu sub-menu text-lg">
						<li>
							<Link
								to={isBidsPage ? '/what-is-unpakt-bids/' : '/what-is-unpakt/'}>
								What is Unpakt
							</Link>
						</li>
						{!isBidsPage && (
							<>
								<li>
									<Link to="/what-is-included/">What is included</Link>
								</li>
								<li>
									<Link to="/how-it-works/">How it Works</Link>
								</li>
								<li>
									<Link to="/press-page/">Press</Link>
								</li>
							</>
						)}
						<li>
							<Link to="/agreement/"> Agreement</Link>
						</li>
						<li>
							<Link to="/privacy/"> Privacy Policy</Link>
						</li>
					</ul>
				</li>
				{!isBidsPage && (
					<>
						<li className="menu-column left col-12 col-md-2 margin-column-1">
							<Link className="menu-title text-2xl" to="/partnership/">
								Partnerships
							</Link>
							<ul className="footer-about-menu sub-menu text-lg">
								<li>
									<Link to="/partnership/">Become a Partner</Link>
								</li>
								<li>
									<Link to="/service-providers/"> For Service Providers</Link>
								</li>
								<li>
									<Link to="/corporate-relocation/"> Corporate Relocation</Link>
								</li>
								<li>
									<a href="https://blog.unpakt.com/real-estate-agent-referral-program/">
										For Real Estate Agents
									</a>
								</li>
							</ul>
						</li>
						<li className="menu-column left col-12 col-md-2 margin-column-2">
							<Link className="menu-title text-2xl" to="/moving-services/">
								Moving Services
							</Link>
							<ul className="footer-services-menu sub-menu text-lg">
								<li>
									<Link to="/moving-local/">Local Moving</Link>
								</li>
								<li>
									<Link to="/moving-long/">Interstate Moving</Link>
								</li>
								<li>
									<Link to="/moving-companies/">Local Moving Companies</Link>
								</li>
								<li>
									<Link to="/moving-truck-rental/">Moving Truck Rental</Link>
								</li>
								<li>
									<Link to="/professional-movers/">
										Hiring Professional Movers
									</Link>
								</li>
							</ul>
							<div className="inner-sub-menu">
								<Link className="menu-title text-2xl" to="/storage-services/">
									{' '}
									Storage Services
								</Link>
								<ul className="sub-menu text-lg">
									<li>
										<Link to="/storage-warehouse/"> Warehouse</Link>
									</li>
									<li>
										<Link to="/storage-self/"> Self Storage</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="menu-column left col-12 col-md-2">
							<a
								className="menu-title text-2xl"
								href="https://blog.unpakt.com/">
								Unpakt Blog
							</a>
							<ul className="footer-guides-menu sub-menu text-lg">
								<li>
									<a href="https://blog.unpakt.com/category/moving-advice-tips/">
										Moving Tips
									</a>
								</li>
								<li>
									<a href="https://blog.unpakt.com/category/moving-cost/">
										Moving Costs
									</a>
								</li>
								<li>
									<a href="https://blog.unpakt.com/category/how-to-before-moving/">
										Before Moving
									</a>
								</li>
								<li>
									<Link to="/deals">Deals</Link>
								</li>
							</ul>
							<div className="inner-sub-menu">
								<Link className="menu-title text-2xl" to="/customer-service">
									Customer Support
								</Link>
								<ul className="sub-menu text-lg">
									<li>
										<Link to="/customer-service/"> Contact Us</Link>
									</li>
									<li>
										<Link to="/faq/"> FAQ</Link>
									</li>
								</ul>
							</div>
						</li>
					</>
				)}
			</ul>
			<style jsx>{`
				* {
					margin: 0;
					padding: 0;
					cursor: pointer;
				}
				.footer-menu {
					font-size: var(--footer-font-size);
					color: white !important;
					padding: 0;
					line-height: 20px;
					margin-bottom: 10px;
					justify-content: center;
				}
				:global(.footer-menu .menu-title) {
					font-family: ProximaNovaBold, sans-serif;
					text-transform: uppercase;
					font-weight: 600;
					color: #fff;
					padding: 0;
					font-size: 16px;
				}
				a,
				:global(.sub-menu a) {
					color: white;
					text-decoration: none;
					padding: 0;
					font-size: 14px;
				}
				a:hover,
				:global(.sub-menu a:hover),
				:global(.footer-menu .menu-title:hover) {
					transition: all 0.2s ease-in;
					color: #2bbdec !important;
				}
				li {
					list-style-type: none;
					padding: 0;
				}
				.menu-column {
					padding: 0;
				}
				.footer-about-menu {
					margin-top: 5px;
				}
				.inner-sub-menu {
					padding-top: 15px;
				}
				:global(.text-lg) {
					font-size: 1.125rem;
					line-height: 1.75rem;
				}
				:global(.text-2xl) {
					font-size: 1.5rem;
					line-height: 2rem;
				}
				@media screen and (min-width: 768px) {
					.margin-column-1 {
						margin-right: 20px;
					}
					.margin-column-2 {
						margin-right: 15px;
					}
				}
			`}</style>
		</div>
	);
};

export default SiteLinks;
