import {
	LOAD_FACEBOOK,
	LOAD_TWITTER,
	NETWORK_SUCCESS,
	SHARE,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import { getCurrentMoveplan } from '../actions/movePlan';
import { share } from '../actions/social';
//const FB = window.FB;

const socialMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type === LOAD_TWITTER) {
			window.twttr = (function (d, s, id) {
				let js,
					fjs = d.getElementsByTagName(s)[0],
					t = window.twttr || {};
				if (d.getElementById(id)) return t;
				js = d.createElement(s);
				js.id = id;
				js.src = 'https://platform.twitter.com/widgets.js';
				fjs.parentNode.insertBefore(js, fjs);

				t._e = [];
				t.ready = function (f) {
					t._e.push(f);
					t.events.bind('tweet', function (event) {
						dispatch(share('twitter'));
					});
				};

				return t;
			})(document, 'script', 'twitter-wjs');
		}
		if (action.type === SHARE) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${
						getState().movePlan.currentMPID
					}/share`,
					method: 'PATCH',
					params: {
						social_network: action.payload,
					},
					fromAction: SHARE,
				})
			);
		}

		if (action.type === LOAD_FACEBOOK) {
			window.fbAsyncInit = function () {
				window.FB.init({
					appId: process.env.REACT_APP_FB_APP_ID,
					autoLogAppEvents: true,
					xfbml: true,
					version: 'v4.0',
				});
			};
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case SHARE:
					dispatch(getCurrentMoveplan());
					break;
				default:
					break;
			}
		}
	};

export default socialMiddleware;
