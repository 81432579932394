import {
	GET_PRESIGNED_URL,
	UPLOAD_FILE,
	CONFIRM_S3_UPLOAD,
	FETCH_EXAMPLE_UPLOAD,
	FETCH_COMPLETED_UPLOAD,
	UPLOAD_COI,
	NETWORK_SUCCESS,
	DELETE_UPLOAD,
} from '../actionTypes.js';
import { uploadFileRequest } from '../actions/api';
import { confirmUpload, setS3UploadURL } from '../actions/s3';
import { getCurrentMoveplan } from '../actions/movePlan';

import { apiRequest } from '../actions/api';

const s3Middleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type === UPLOAD_COI) {
			if (!!action.payload) {
				dispatch(
					apiRequest({
						url: `api/v3/coi_upload/get_presigned_post`,
						method: 'GET',
						params: {
							// filename pattern: <short mpid>_<route key>_<'example' or 'completed'>
							filename: `${
								getState().movePlan.shortMPID ||
								getState().movePlan.dummyMPID?.slice(0, 6).toUpperCase()
							}-${action.payload.key}-${action.payload.case}.${
								/(?:\.([^.]+))?$/.exec(action.payload.file.name)[1]
							}`,
						},
						fromAction: GET_PRESIGNED_URL,
						file: action.payload.file,
					})
				);
			}
		}

		if (action.type === CONFIRM_S3_UPLOAD) {
			dispatch(
				apiRequest({
					url: `api/v3/coi_upload/confirm_s3_upload`,
					method: 'POST',
					params: {
						filename: action.payload,
					},
					fromAction: CONFIRM_S3_UPLOAD,
				})
			);
		}

		if (action.type === FETCH_EXAMPLE_UPLOAD) {
			dispatch(
				apiRequest({
					url: 'api/v3/coi_upload/fetch_upload',
					method: 'GET',
					params: {
						filename:
							getState().movePlan.currentPlan.details[action.payload]
								.example_coi,
					},
					fromAction: FETCH_EXAMPLE_UPLOAD,
					callback: action.payload,
				})
			);
		}

		if (action.type === FETCH_COMPLETED_UPLOAD) {
			dispatch(
				apiRequest({
					url: 'api/v3/coi_upload/fetch_upload',
					method: 'GET',
					params: {
						filename:
							getState().movePlan.currentPlan.details[action.payload]
								.completed_coi,
					},
					fromAction: FETCH_COMPLETED_UPLOAD,
					callback: action.payload,
				})
			);
		}

		if (action.type === DELETE_UPLOAD) {
			dispatch(
				apiRequest({
					url: 'api/v3/coi_upload/delete_upload',
					method: 'DELETE',
					params: {
						filename: action.payload,
					},
					fromAction: DELETE_UPLOAD,
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case GET_PRESIGNED_URL:
					let formData = new FormData();
					// AWS needs things in THIS EXACT FUCKING ORDER FOR SOME GGODDDDDAMMMMMMMM REAASON. >:()
					formData.append(
						'key',
						action.payload.response.data.url_fields['key']
					);
					formData.append(
						'filename',
						action.meta.originalRequest.params.filename
					);
					Object.keys(action.payload.response.data.url_fields).forEach(key => {
						if (key !== 'key')
							formData.append(
								key,
								action.payload.response.data.url_fields[key]
							);
					});
					formData.append('file', action.meta.originalRequest.file);
					dispatch(
						uploadFileRequest({
							url: action.payload.response.data.url,
							params: formData,
							fromAction: UPLOAD_FILE,
							file: { name: action.meta.originalRequest.params.filename },
						})
					);
					break;
				case UPLOAD_FILE:
					dispatch(confirmUpload(action.meta.originalRequest.file.name));
					break;
				case CONFIRM_S3_UPLOAD:
					dispatch(getCurrentMoveplan());
					// if (window.location.pathname !== '/v3/' || !isV3) {
					// 	window.location.reload();
					// }
					break;
				case FETCH_EXAMPLE_UPLOAD:
					dispatch(
						setS3UploadURL(
							action.payload.response.data.url,
							action.meta.originalRequest.callback
						)
					);
					break;
				case FETCH_COMPLETED_UPLOAD:
					dispatch(
						setS3UploadURL(
							action.payload.response.data.url,
							action.meta.originalRequest.callback,
							true
						)
					);
					break;
				case DELETE_UPLOAD:
					dispatch(getCurrentMoveplan());
					break;
				default:
					break;
			}
		}
	};

export default s3Middleware;
