import {
	GOTO_BOOK,
	GOTO_BOXES,
	GOTO_COMPARE,
	GOTO_CONGRATS,
	GOTO_LAST_STEP,
	GOTO_MY_INVENTORY,
	GOTO_PLAN,
	GOTO_STEP,
	NETWORK_SUCCESS,
	NEXT_STEP,
	PRINT_PAGE,
	IMPORT_FUNNEL_STATE,
	OPEN_OLARK,
	INIT_HOURLY_LAST_STEP,
} from '../actionTypes';
import {
	gotoBook,
	gotoCompare,
	gotoMyInventory,
	goToStep,
	setCurrentStep,
	setLastStep,
	setStepCompleted,
} from '../actions/funnel';
import { setInventoryView } from '../actions/inventory';
import { push } from 'connected-react-router';
import { allowedToVisit } from '../reducers/funnel';
import { apiRequest } from '../actions/api';
import { FUNNEL_STEPS, FUNNEL_STEPS_BIDS, HOURLY_STEPS } from '../../constants';
import { openModal, closeAllModals } from '../actions/modals';

const funnelMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type === NEXT_STEP) {
			dispatch(closeAllModals());
			let isHourly = !!getState().movePlan.currentPlan.hourly_plan;
			if (isHourly) {
				dispatch(
					openModal({
						name: 'loader',
						properties: { state: 'selecting_mover' },
					})
				);
			} else {
				dispatch(
					openModal({ name: 'loader', properties: { state: 'initial' } })
				);
			}
			// dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}))
			let lastStep = getState().funnel.currentStep;

			let nextStep = null;
			if (getState().movePlan.currentPlan.is_booked) {
				if (lastStep === 'INVENTORY') {
					window.parent.postMessage('submitInventory', '*');
				}
				dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
			} else {
				if (isHourly) {
					switch (lastStep) {
						case 'DETAILS':
							dispatch(setStepCompleted('DETAILS'));
							break;
						case 'COMPARE':
							dispatch(setStepCompleted('COMPARE'));
							break;
						case 'BOOK':
							dispatch(setStepCompleted('BOOK'));
							break;
						default:
							break;
					}
					nextStep = HOURLY_STEPS[lastStep].nextStep;
				} else {
					switch (lastStep) {
						case 'INVENTORY':
							dispatch(setStepCompleted('INVENTORY'));
							break;
						case 'DETAILS':
							dispatch(setStepCompleted('DETAILS'));
							break;
						case 'COMPARE':
							dispatch(setStepCompleted('COMPARE'));
							break;
						case 'BOOK':
							dispatch(setStepCompleted('BOOK'));
							break;
						default:
							break;
					}
					if (window.location.href.indexOf('movingbids') === -1) {
						nextStep = FUNNEL_STEPS[lastStep].nextStep;
					} else {
						nextStep = FUNNEL_STEPS_BIDS[lastStep].nextStep;
					}
				}
				dispatch(
					apiRequest({
						url: `api/v3/move_plans/${
							getState().movePlan.currentMPID
						}/set_step`,
						method: 'PATCH',
						params: {
							step: nextStep,
						},
						fromAction: NEXT_STEP,
					})
				);
			}
		}
		if (action.type === INIT_HOURLY_LAST_STEP) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${getState().movePlan.currentMPID}/set_step`,
					method: 'PATCH',
					params: {
						step: 'COMPARE',
					},
					fromAction: INIT_HOURLY_LAST_STEP,
				})
			);
		}
		if (action.type === GOTO_MY_INVENTORY) {
			dispatch(setCurrentStep('INVENTORY'));
			if (window.location.href.indexOf('movingbids') === -1) {
				dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
			} else {
				dispatch(
					push(`/movingbids/${getState().movePlan.currentMPID}/inventory`)
				);
			}
		}
		if (action.type === GOTO_COMPARE) {
			dispatch(setCurrentStep('COMPARE'));
			if (getState().funnel.currentStep !== 'INVENTORY') {
				if (window.location.href.indexOf('movingbids') === -1) {
					dispatch(push(`/moving/${getState().movePlan.currentMPID}/compare`));
				} else {
					dispatch(
						push(`/movingbids/${getState().movePlan.currentMPID}/compare`)
					);
				}
			}
		}
		if (action.type === GOTO_PLAN) {
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
		}
		if (action.type === GOTO_BOOK) {
			dispatch(setCurrentStep('BOOK'));
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/book`));
		}
		if (action.type === GOTO_CONGRATS) {
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/congrats`));
		}

		if (action.type === GOTO_BOXES) {
			dispatch(setInventoryView('BOXES'));
		}

		if (action.type === GOTO_STEP) {
			switch (action.payload) {
				case 'INVENTORY':
					dispatch(gotoMyInventory());
					break;
				case 'DETAILS':
					if (allowedToVisit(getState(), action.payload)) {
						if (window.location.href.indexOf('movingbids') === -1) {
							dispatch(
								push(`/moving/${getState().movePlan.currentMPID}/details`)
							);
						} else {
							dispatch(
								push(`/movingbids/${getState().movePlan.currentMPID}/details`)
							);
						}
					}
					break;
				case 'COMPARE':
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(gotoCompare());
					}
					break;
				case 'PLAN':
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(setCurrentStep('PLAN'));
						dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
					}
					break;
				case 'BOOK':
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(gotoBook());
					}
					break;
				default:
					break;
			}
		}

		if (action.type === GOTO_LAST_STEP) {
			dispatch(
				goToStep(getState().movePlan.currentPlan.last_step.toUpperCase())
			);
		}

		if (action.type === PRINT_PAGE) {
			if (action.payload.isCurrentPage) {
				let content = document.getElementById('printmovePlan');
				let pri = document.getElementById('ifmcontentstoprint').contentWindow;
				pri.document.open();
				pri.document.write(content.innerHTML);
				pri.document.close();
				pri.focus();
				pri.print();
			} else {
				let content = document.getElementById('print-container');
				let pri = document.getElementById('ifmcontentstoprint').contentWindow;
				pri.document.open();
				pri.document.write(content.innerHTML);
				pri.document.close();
				pri.focus();
				pri.print();
				//signal to react to load movePlan component out of sight
				//prepare this to print
				// do the same as above
			}
		}

		if (action.type === IMPORT_FUNNEL_STATE) {
			const stateKeys = Object.keys(getState().funnel.progressBarSteps);
			const incomingState = action.payload.toUpperCase();
			const j = stateKeys.indexOf(incomingState);
			for (let i = 0; i < j; i++) {
				dispatch(setStepCompleted(stateKeys[i]));
			}
		}

		if (action.type === OPEN_OLARK) {
			if (process.env.REACT_APP_STAGE === 'prod') {
				window.olark('api.box.expand');
			} else {
				alert('This will open Olark chat on production.');
			}
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case NEXT_STEP:
					// set last step to completed
					let lastStep = getState().funnel.currentStep;
					let nextStep = getState().funnel.progressBarSteps[lastStep].nextStep;
					let nextPath = nextStep.toLowerCase();
					dispatch(setLastStep(lastStep));
					dispatch(goToStep(nextStep));
					if (window.location.href.indexOf('movingbids') === -1) {
						dispatch(
							push(`/moving/${getState().movePlan.currentMPID}/${nextPath}`)
						);
					} else {
						dispatch(
							push(`/movingbids/${getState().movePlan.currentMPID}/${nextPath}`)
						);
					}
					break;
				default:
					break;
			}
		}
	};

export default funnelMiddleware;
