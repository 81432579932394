import {
	FETCH_INVENTORY,
	FETCH_ALL_INVENTORY,
	NETWORK_SUCCESS,
	ADD_TYPICALS,
	ADD_TYPICAL_ITEMS,
	ADD_TYPICAL_BOXES,
	REMOVE_TYPICALS,
	REMOVE_TYPICAL_ITEMS,
	REMOVE_TYPICAL_BOXES,
	UPDATE_INVENTORY_SEARCH_QUERY,
	ADD_ITEM,
	REMOVE_ITEM,
	ADD_BOX,
	REMOVE_BOX,
	CREATE_CUSTOM_ITEM,
	ADD_CUSTOM_ITEM,
	REMOVE_CUSTOM_ITEM,
	UPDATE_SPECIAL_HANDLING,
	ADD_SPECIAL_HANDLING_ITEMS,
	INIT_INVENTORY_PAGE,
	ADD_INVENTORY_PRICING,
	CREATE_NEW_PLAN,
	UPDATE_ITEM_COUNT,
	UPDATE_BOX_COUNT,
	NETWORK_FAILURE,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
	updateCustomItems,
	updateInventoryBoxes,
	updateInventoryItems,
	setAllInventory,
	fetchInventory,
	toggleHasTypicals,
	updateRoomCounts,
	toggleHasTypicalBoxes,
	updateSearchResults,
	setLastInventoryAction,
	updateCubicFeet,
	initSpecialHandlingItems,
	fetchAllInventory,
	setInventoryPricing,
	setInventoryView,
	setInventoryLoading,
	setSuggestedBoxes,
} from '../actions/inventory';

import { getBids } from '../actions/compare';
import { closeModal, openModal } from '../actions/modals';
import {
	getCurrentMoveplan,
	setCurrentMoveplanID,
	updateMovePlan,
} from '../actions/movePlan';
import { setCurrentStep, setLastStep } from '../actions/funnel';
import { setPlanUpdating } from '../actions/plan';
import { setHomePageLoading } from '../actions/inventory';
import has from 'lodash-es/has';
import { push } from 'connected-react-router';

const inventoryMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		const movePlanMPID =
			getState().movePlan.currentMPID || getState().movePlan.dummyMPID;

		let isV3 = localStorage.getItem('v3');

		if (action.type === INIT_INVENTORY_PAGE) {
			dispatch(setInventoryLoading(true));
			dispatch(setCurrentStep('INVENTORY'));
			if (getState().funnel.lastStep === '') dispatch(setLastStep('INVENTORY'));
			if (!!!getState().inventory.currentInventoryView) {
				dispatch(setInventoryView('INVENTORY'));
			}
			if (movePlanMPID !== action.payload.movePlanId) {
				dispatch(setCurrentMoveplanID(action.payload.movePlanId));
			}
			dispatch(getCurrentMoveplan());
			dispatch(fetchInventory(movePlanMPID));
			dispatch(fetchAllInventory(movePlanMPID));
		}

		if (action.type === FETCH_INVENTORY) {
			if (window.location.pathname === '/v3/' || isV3) {
				dispatch(
					apiRequest({
						url: `${process.env.REACT_APP_API_URL}/inventory_items/move_plan_inventory_items`,
						method: 'GET',
						params: {
							move_plan_id: movePlanMPID,
							v3: true,
						},
						fromAction: FETCH_INVENTORY,
					})
				);
			} else {
				dispatch(
					apiRequest({
						url: `${process.env.REACT_APP_API_URL}/inventory_items/move_plan_inventory_items`,
						method: 'GET',
						params: {
							move_plan_id: movePlanMPID,
						},
						fromAction: FETCH_INVENTORY,
					})
				);
			}
		}

		if (action.type === FETCH_ALL_INVENTORY) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/inventory_items/all_items`,
					method: 'GET',
					params: {
						move_plan_id: movePlanMPID,
					},
					fromAction: FETCH_ALL_INVENTORY,
				})
			);
		}
		if (action.type === ADD_TYPICALS) {
			dispatch(setInventoryLoading(true));
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes/add_typicals`,
					method: 'POST',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: ADD_TYPICALS,
				})
			);
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/add_typicals`,
					method: 'POST',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: ADD_TYPICALS,
				})
			);

			dispatch(setLastInventoryAction(action));
		}
		if (action.type === ADD_TYPICAL_ITEMS) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/add_typicals`,
					method: 'POST',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: ADD_TYPICAL_ITEMS,
				})
			);
		}
		if (action.type === ADD_TYPICAL_BOXES) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes/add_typicals`,
					method: 'POST',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: ADD_TYPICAL_BOXES,
				})
			);
			dispatch(setLastInventoryAction(action));
		}
		if (action.type === REMOVE_TYPICALS) {
			dispatch(setInventoryLoading(true));
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/remove_typicals`,
					method: 'DELETE',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: REMOVE_TYPICALS,
				})
			);
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes/remove_typicals`,
					method: 'DELETE',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: REMOVE_TYPICALS,
				})
			);
			dispatch(toggleHasTypicals());
			dispatch(setLastInventoryAction(action));
		}
		if (action.type === REMOVE_TYPICAL_ITEMS) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/remove_typicals`,
					method: 'DELETE',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: REMOVE_TYPICAL_ITEMS,
				})
			);
		}
		if (action.type === REMOVE_TYPICAL_BOXES) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes/remove_typicals`,
					method: 'DELETE',
					params: {
						movePlanId: movePlanMPID,
					},
					fromAction: REMOVE_TYPICAL_BOXES,
				})
			);
		}
		if (action.type === UPDATE_INVENTORY_SEARCH_QUERY) {
			dispatch(updateSearchResults());
		}
		if (action.type === ADD_ITEM) {
			if (has(getState().movePlan.currentPlan, 'mover')) {
				dispatch(setInventoryLoading(true));
			}
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items`,
					method: 'POST',
					params: {
						inventory_item: {
							inventory_item_id: action.payload.id,
							item_group_id: action.payload.group_id,
						},
					},
					fromAction: ADD_ITEM,
				})
			);
			dispatch(setLastInventoryAction(action));
		}
		if (action.type === REMOVE_ITEM) {
			if (has(getState().movePlan.currentPlan, 'mover')) {
				dispatch(setInventoryLoading(true));
			}
			let payload = 0;
			payload =
				action.payload.mp_inventory_item_id[
					action.payload.mp_inventory_item_id.length - 1
				];
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/${payload}`,
					method: 'DELETE',
					fromAction: REMOVE_ITEM,
				})
			);
			dispatch(setLastInventoryAction(action));
		}

		if (action.type === UPDATE_ITEM_COUNT) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/set_item_count`,
					method: 'POST',
					params: {
						inventory_item_id: action.payload.item.id,
						item_group_id: action.payload.item.group_id,
						count: action.payload.count,
					},
					fromAction: UPDATE_ITEM_COUNT,
				})
			);
		}

		if (action.type === ADD_BOX) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes`,
					method: 'POST',
					params: {
						box: {
							box_type_id: action.payload.box_type_id,
							quantity: action.payload.quantity,
						},
					},
					fromAction: ADD_BOX,
				})
			);
			dispatch(setLastInventoryAction(action));
		}
		if (action.type === REMOVE_BOX) {
			if (action.payload.quantity <= 0) {
				dispatch(
					apiRequest({
						url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes`,
						method: 'POST',
						params: {
							box: {
								box_type_id: action.payload.box_type_id,
								quantity: 0,
							},
						},
						fromAction: REMOVE_BOX,
					})
				);
			} else {
				dispatch(
					apiRequest({
						url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes`,
						method: 'POST',
						params: {
							box: {
								box_type_id: action.payload.box_type_id,
								quantity: action.payload.quantity,
							},
						},
						fromAction: ADD_BOX,
					})
				);
			}
			dispatch(setLastInventoryAction(action));
		}
		if (action.type === UPDATE_BOX_COUNT) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/boxes`,
					method: 'POST',
					params: {
						box: {
							box_type_id: action.payload.box.box_type_id,
							quantity: action.payload.count,
						},
					},
					fromAction: UPDATE_BOX_COUNT,
				})
			);
			dispatch(setLastInventoryAction(action));
		}
		if (action.type === CREATE_CUSTOM_ITEM) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/custom`,
					method: 'POST',
					params: {
						custom_inventory_item: {
							name: action.payload.name,
							width_in_inches: action.payload.width_in_inches,
							height_in_inches: action.payload.height_in_inches,
							depth_in_inches: action.payload.depth_in_inches,
						},
					},
					fromAction: CREATE_CUSTOM_ITEM,
				})
			);
		}
		if (action.type === ADD_CUSTOM_ITEM) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/`,
					method: 'POST',
					params: {
						inventory_item: {
							inventory_item_id: action.payload.id,
						},
					},
					fromAction: ADD_CUSTOM_ITEM,
				})
			);
		}
		if (action.type === REMOVE_CUSTOM_ITEM) {
			dispatch(
				apiRequest({
					url: `${
						process.env.REACT_APP_API_URL
					}/move_plans/${movePlanMPID}/inventory_items/${
						action.payload.mp_inventory_item_id[
							action.payload.mp_inventory_item_id.length - 1
						]
					}`,
					method: 'DELETE',
					fromAction: REMOVE_CUSTOM_ITEM,
				})
			);
		}
		if (action.type === UPDATE_SPECIAL_HANDLING) {
			let payloadItems = [];
			let specialHandlingItems = getState().inventory.specialHandlingItems;
			dispatch(setPlanUpdating(true));
			for (let itemKey in specialHandlingItems) {
				payloadItems.push({
					mp_inventory_item_id: itemKey,
					wall_removal_required:
						specialHandlingItems[itemKey].wall_removal_required,
					crating_required: specialHandlingItems[itemKey].crating_required,
					assembly_required: specialHandlingItems[itemKey].assembly_required,
				});
			}
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/move_plans/${movePlanMPID}/inventory_items/special_handling`,
					method: 'PATCH',
					params: {
						special_handling_items: payloadItems,
					},
					fromAction: UPDATE_SPECIAL_HANDLING,
					meta: { shouldFetchInventory: action.payload },
				})
			);
		}
		if (action.type === ADD_SPECIAL_HANDLING_ITEMS) {
			dispatch(closeModal());
		}

		if (action.type === ADD_INVENTORY_PRICING) {
			let inventory = getState().inventory.inventoryItems;
			let boxes = getState().inventory.inventoryBoxes;
			let custom = getState().inventory.customItems;

			getState().compare.breakdown.rooms.map(room =>
				room.items.map(item => {
					inventory[item.room_id][item.id].handling_fee = item.handling_fee;
					inventory[item.room_id][item.id].volume_fee = item.volume_fee;
					inventory[item.room_id][item.id].total_fee =
						(item.handling_fee + item.volume_fee) *
						inventory[item.room_id][item.id].mp_inventory_item_id.length;
					return null;
				})
			);

			getState().compare.breakdown.boxes.map(box => {
				if (!!boxes[box.box_type_id]) {
					boxes[box.box_type_id].handling_fee = box.handling_fee;
					boxes[box.box_type_id].volume_fee = box.volume_fee;
					boxes[box.box_type_id].total_fee =
						(box.volume_fee + box.handling_fee) * box.quantity;
				}
				return null;
			});

			getState().compare.breakdown.custom_items.map(item => {
				custom[item.id].handling_fee = item.handling_fee;
				custom[item.id].volume_fee = item.volume_fee;
				custom[item.id].total_fee =
					(item.handling_fee + item.volume_fee) *
					custom[item.id].mp_inventory_item_id.length;
				return null;
			});
			dispatch(
				setInventoryPricing({
					inventoryItems: inventory,
					inventoryBoxes: boxes,
					customItems: custom,
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case FETCH_INVENTORY:
					const {
						boxes: inventoryBoxes,
						custom_items: customList,
						items: inventoryList,
					} = action.payload.response.data;
					const inventoryItems = {};
					const roomCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
					let cubicFeet = 0;

					// todo: work with Amery to create an endpoint that gives things formatted exactly like below
					for (let k = 1; k < 11; k++) {
						let temp = inventoryList.filter(item => item.room_id === k);
						inventoryItems[k] = {};
						// eslint-disable-next-line
						temp.forEach(item => {
							cubicFeet += item.cubic_feet;
							if (typeof inventoryItems[k][item.id] === 'undefined') {
								inventoryItems[k][item.id] = item;
								inventoryItems[k][item.id].mp_inventory_item_id = [
									item.mp_inventory_item_id,
								];
								roomCounts[k - 1] += 1;
								return true;
							} else {
								inventoryItems[k][item.id].mp_inventory_item_id.push(
									item.mp_inventory_item_id
								);
								roomCounts[k - 1] += 1;
								return true;
							}
						});
					}

					let boxes = {};
					inventoryBoxes.map(box => {
						boxes[box.box_type_id] = box;
						cubicFeet += box.cubic_feet * box.quantity;
						return true;
					});

					let customItems = {};
					customList.map(item => {
						if (!!!customItems[item.id]) {
							customItems[item.id] = item;
							customItems[item.id].mp_inventory_item_id = [
								item.mp_inventory_item_id,
							];
							cubicFeet += item.cubic_feet;
							return true;
						} else {
							customItems[item.id].mp_inventory_item_id.push(
								item.mp_inventory_item_id
							);
							cubicFeet += item.cubic_feet;
							return true;
						}
					});

					dispatch(updateInventoryItems({ inventoryItems }));
					dispatch(updateInventoryBoxes({ boxes }));
					dispatch(updateCustomItems({ customItems }));
					dispatch(updateRoomCounts({ roomCounts }));
					dispatch(
						setHomePageLoading({
							boxes: false,
							furniture: false,
						})
					);
					dispatch(updateCubicFeet(Math.ceil(cubicFeet)));
					dispatch(
						setHomePageLoading({
							cf: false,
						})
					);
					dispatch(
						initSpecialHandlingItems({
							items: inventoryList,
							customItems: customList,
						})
					);
					dispatch(setInventoryLoading(false));
					dispatch(getBids());
					break;
				case FETCH_ALL_INVENTORY:
					// TODO: Remove this code when the API endpoint decides on a single naming convention for boxes
					action.payload.response.data.boxes.map(box => {
						if (box.id !== box.box_type_id) {
							Object.defineProperty(
								box,
								'box_type_id',
								Object.getOwnPropertyDescriptor(box, 'id')
							);
							delete box['id'];
						}
						return true;
					});
					dispatch(setAllInventory(action.payload.response.data));
					if (!!!getState().inventory.suggestedBoxes) {
						dispatch(setSuggestedBoxes());
					}

					break;
				case ADD_TYPICALS:
					dispatch(toggleHasTypicals());
					dispatch(setLastInventoryAction(action));
					dispatch(
						fetchInventory(action.meta.originalRequest.params.movePlanId)
					);
					break;
				case ADD_TYPICAL_ITEMS:
					dispatch(
						fetchInventory(action.meta.originalRequest.params.movePlanId)
					);
					break;
				case ADD_TYPICAL_BOXES:
					dispatch(toggleHasTypicalBoxes());
					dispatch(
						fetchInventory(action.meta.originalRequest.params.movePlanId)
					);
					break;
				case REMOVE_TYPICALS:
					dispatch(toggleHasTypicals());
					dispatch(
						fetchInventory(action.meta.originalRequest.params.movePlanId)
					);
					break;
				case REMOVE_TYPICAL_ITEMS:
					dispatch(
						fetchInventory(action.meta.originalRequest.params.movePlanId)
					);
					break;
				case REMOVE_TYPICAL_BOXES:
					dispatch(
						fetchInventory(action.meta.originalRequest.params.movePlanId)
					);
					dispatch(toggleHasTypicalBoxes());
					break;
				case ADD_ITEM:
					dispatch(fetchInventory(movePlanMPID));
					if (has(getState().movePlan.currentPlan, 'mover')) {
						dispatch(getCurrentMoveplan());
					}
					break;
				case REMOVE_ITEM:
					dispatch(fetchInventory(movePlanMPID));
					if (has(getState().movePlan.currentPlan, 'mover')) {
						dispatch(getCurrentMoveplan());
					}
					break;
				case UPDATE_ITEM_COUNT:
					dispatch(fetchInventory(movePlanMPID));
					if (has(getState().movePlan.currentPlan, 'mover')) {
						dispatch(getCurrentMoveplan());
					}
					break;
				case ADD_BOX:
					dispatch(fetchInventory(movePlanMPID));
					break;
				case REMOVE_BOX:
					dispatch(fetchInventory(movePlanMPID));
					break;
				case UPDATE_BOX_COUNT:
					dispatch(fetchInventory(movePlanMPID));
					break;
				case CREATE_CUSTOM_ITEM:
					dispatch(fetchInventory(movePlanMPID));
					dispatch(closeModal());
					break;
				case ADD_CUSTOM_ITEM:
					dispatch(fetchInventory(movePlanMPID));
					break;
				case REMOVE_CUSTOM_ITEM:
					dispatch(fetchInventory(movePlanMPID));
					break;
				case UPDATE_SPECIAL_HANDLING:
					dispatch(updateMovePlan(getState().movePlan.currentPlan.uuid));
					break;
				case CREATE_NEW_PLAN:
					// todo: set MPID, redirect to the inventory page
					dispatch(closeModal());
					dispatch(setCurrentMoveplanID(action.payload.response.data.uuid));
					dispatch(
						push(`/moving/${action.payload.response.data.uuid}/inventory`)
					);
					break;
				default:
					break;
			}
		}
		if (action.type === NETWORK_FAILURE) {
			switch (action.meta.originalRequest.fromAction) {
				case FETCH_INVENTORY:
				case ADD_TYPICALS:
				case ADD_TYPICAL_ITEMS:
				case ADD_TYPICAL_BOXES:
				case REMOVE_TYPICALS:
				case REMOVE_TYPICAL_ITEMS:
				case REMOVE_TYPICAL_BOXES:
				case ADD_BOX:
				case REMOVE_BOX:
				case ADD_CUSTOM_ITEM:
				case ADD_ITEM:
				case REMOVE_ITEM:
				case UPDATE_BOX_COUNT:
				case UPDATE_ITEM_COUNT:
				case UPDATE_SPECIAL_HANDLING:
				case CREATE_NEW_PLAN:
					dispatch(
						openModal({
							name: 'apiError',
							properties: {
								error: 'Something went wrong updating the inventory...',
							},
						})
					);
					break;
				default:
					break;
			}
		}
	};

export default inventoryMiddleware;
