import {
	GET_SUGGESTIONS,
	SELECT_SUGGESTION,
	SET_SUGGESTIONS,
	SET_SCRIPT_LOADING,
	SET_SELECTED_DNA_TAB,
	SET_PPC_NYC_SELECTED_DNA_TAB,
	SET_PREV_PICK_UP_ADDRESS,
	SET_PREV_DROP_OFF_ADDRESS,
	SET_CURRENT_PICK_UP_ADDRESS,
	SET_CURRENT_ADDRESS,
	SET_DUMMY_PAYLOAD_FOR_MOVE_PLAN,
	SET_CURRENT_DROP_OFF_ADDRESS,
} from '../actionTypes';

export const getSuggestions = (query, useExact) => ({
	type: GET_SUGGESTIONS,
	payload: { query: query, useExact: useExact },
});

export const setSuggestions = suggestions => ({
	type: SET_SUGGESTIONS,
	payload: suggestions,
});

export const selectSuggestion = (suggestion, key) => ({
	type: SELECT_SUGGESTION,
	payload: { suggestion: suggestion, key: key },
});

export const setScriptLoading = bool => ({
	type: SET_SCRIPT_LOADING,
	payload: bool,
});

export const setSelectedDNATab = tab => ({
	type: SET_SELECTED_DNA_TAB,
	payload: tab,
});

export const setPpcNycSelectedDNATab = tab => ({
	type: SET_PPC_NYC_SELECTED_DNA_TAB,
	payload: tab,
});

export const setPrevPickUpAddress = prevPickUpAddress => ({
	type: SET_PREV_PICK_UP_ADDRESS,
	payload: prevPickUpAddress,
});

export const setPrevDropOffAddress = prevDropOffAddress => ({
	type: SET_PREV_DROP_OFF_ADDRESS,
	payload: prevDropOffAddress,
});

export const setCurrentAddress = currentAddress => ({
	type: SET_CURRENT_ADDRESS,
	payload: currentAddress,
});

export const setDummyPayloadForMovePlan = payload => ({
	type: SET_DUMMY_PAYLOAD_FOR_MOVE_PLAN,
	payload,
});

export const setCurrentPickUpAddress = currentPickUpAddress => ({
	type: SET_CURRENT_PICK_UP_ADDRESS,
	payload: currentPickUpAddress,
});

export const setCurrentDropOffAddress = currentPickUpAddress => ({
	type: SET_CURRENT_DROP_OFF_ADDRESS,
	payload: currentPickUpAddress,
});
