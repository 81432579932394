import {
	FILTER_COMPARE_RESULTS,
	GET_BIDS,
	GET_PRICING_BREAKDOWN,
	INIT_COMPARE_PAGE,
	NETWORK_FAILURE,
	NETWORK_SUCCESS,
	SELECT_MOVER,
	SELECT_MOVER_FROM_HOMEPAGE,
	UNSELECT_MOVER_FROM_HOMEPAGE,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
	addDeliveryEstimateOption,
	filterCompareResults,
	setBidPricingBreakdown,
	setBids,
	setCompareLoading,
	setCompareFilter,
	setFilteredResults,
	setModalLoading,
} from '../actions/compare';
import orderBy from 'lodash-es/orderBy';
import { nextStep, setCurrentStep } from '../actions/funnel';
import { setHomePageLoading } from '../actions/inventory';
import {
	getCurrentMoveplan,
	setCurrentMoveplanID,
	setHourlyRenderLoading,
	updateMovePlan,
} from '../actions/movePlan';
import { addInventoryPricing } from '../actions/inventory';
import { saveAccessDate } from '../actions/book';

const compareMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		const movePlanMPID =
			getState().movePlan.currentMPID || getState().movePlan.dummyMPID;

		let isV3 = localStorage.getItem('v3');

		if (action.type === INIT_COMPARE_PAGE) {
			dispatch(setCompareLoading(true));
			dispatch(setHourlyRenderLoading(true));
			dispatch(setCurrentStep('COMPARE'));
			dispatch(setCompareFilter({ value: 1, label: 'PRICE LOW TO HIGH' }));
			if (movePlanMPID !== action.payload?.movePlanId) {
				dispatch(setCurrentMoveplanID(action.payload?.movePlanId));
			}
			dispatch(getCurrentMoveplan());
		}

		if (action.type === GET_BIDS) {
			if (window.location.pathname === '/v3/' || isV3) {
				dispatch(
					apiRequest({
						url: `api/v3/move_plans/${movePlanMPID}/movers?v3=1`,
						method: 'GET',
						fromAction: GET_BIDS,
					})
				);
			} else {
				dispatch(
					apiRequest({
						url: `api/v3/move_plans/${movePlanMPID}/movers`,
						method: 'GET',
						fromAction: GET_BIDS,
					})
				);
			}
		}

		if (action.type === FILTER_COMPARE_RESULTS) {
			let filtered = [];
			let tempBids = getState().compare.bids.filter(
				bid => bid.consult_only === false
			);
			switch (action.payload) {
				case 1:
					filtered = orderBy(tempBids, ['total_cost'], ['asc']);
					break;
				case 2:
					filtered = orderBy(tempBids, ['total_cost'], ['desc']);
					break;
				case 3:
					filtered = orderBy(tempBids, ['index_reviews'], ['desc']);
					break;
				case 4:
					// TODO: this should be tested at some point with long range moving
					filtered = tempBids.sort((a, b) => {
						if (a.delivery_estimate && b.delivery_estimate) {
							let aMax = a.delivery_estimate.maximum_delivery_days;
							let bMax = b.delivery_estimate.maximum_delivery_days;
							let aMin = a.delivery_estimate.minimum_delivery_days;
							let bMin = b.delivery_estimate.minimum_delivery_days;

							if (aMax && bMax && aMin && bMin) {
								if (aMax === bMax && aMin === bMin) {
									return a.total_cost - b.total_cost;
								} else if (aMax === bMax) {
									return aMin - bMin;
								} else return aMax - bMax;
							} else if (!aMax) {
								return 1;
							} else if (!bMax) {
								return -1;
							}
						}
						return -1;
					});
					break;
				default:
					console.log('improper filter valued applied to compare results');
					break;
			}
			dispatch(setFilteredResults(filtered));
		}

		if (action.type === SELECT_MOVER) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${
						movePlanMPID ? movePlanMPID : getState().movePlan.currentPlan.uuid
					}/choose_mover`,
					method: 'POST',
					params: {
						id: action.payload,
					},
					fromAction: SELECT_MOVER,
				})
			);
		}
		if (action.type === SELECT_MOVER_FROM_HOMEPAGE) {
			const currentMPID = movePlanMPID
				? movePlanMPID
				: getState().movePlan.currentPlan.uuid;

			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${currentMPID}/choose_mover_from_homepage`,
					method: 'POST',
					params: {
						id: action.payload,
					},
					fromAction: SELECT_MOVER_FROM_HOMEPAGE,
				})
			);
		}
		if (action.type === UNSELECT_MOVER_FROM_HOMEPAGE) {
			const currentMPID = movePlanMPID
				? movePlanMPID
				: getState().movePlan.currentPlan.uuid;

			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${currentMPID}/unchoose_mover_from_homepage`,
					method: 'POST',
					params: {
						id: action.payload,
					},
					fromAction: UNSELECT_MOVER_FROM_HOMEPAGE,
				})
			);
		}
		if (action.type === GET_PRICING_BREAKDOWN) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${movePlanMPID}/estimates/for_mover`,
					method: 'GET',
					params: {
						mover_id: action.payload,
					},
					fromAction: GET_PRICING_BREAKDOWN,
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case GET_BIDS:
					const { estimates: bidData } = action.payload.response.data;
					let temp = bidData.map(mover => {
						for (let key in mover) {
							if (key === 'delivery_estimate') {
								console.log(mover);
							}
						}
						return true;
					});
					if (temp.some(bid => bid.delivery_estimate === true)) {
						if (!getState().compare.showDeliveryEstimate) {
							dispatch(addDeliveryEstimateOption(true));
						}
					} else {
						if (getState().compare.showDeliveryEstimate) {
							dispatch(addDeliveryEstimateOption(false));
						}
					}
					dispatch(setBids(bidData));
					dispatch(filterCompareResults(getState().compare.filter.value));
					dispatch(
						setHomePageLoading({
							startPrice: false,
						})
					);
					dispatch(setCompareLoading(false));
					setHomePageLoading({
						startPrice: false,
						cf: false,
						boxes: false,
						furniture: false,
					});
					break;
				case GET_PRICING_BREAKDOWN:
					dispatch(setBidPricingBreakdown(action.payload.response.data));
					if (!getState().movePlan.currentPlan.hourly_plan) {
						dispatch(addInventoryPricing());
					}
					dispatch(setModalLoading(false));
					break;
				case SELECT_MOVER:
					dispatch(getCurrentMoveplan());
					dispatch(nextStep());
					dispatch(saveAccessDate(getState().movePlan.currentMPID, new Date()));
					break;
				case SELECT_MOVER_FROM_HOMEPAGE:
					const currentMPID = action.payload.response.data.new_mpid;
					dispatch(setCurrentMoveplanID(currentMPID));
					dispatch(getCurrentMoveplan());
					break;
				case UNSELECT_MOVER_FROM_HOMEPAGE:
					if (getState().compare.changeMover) {
						dispatch(
							setHomePageLoading({
								startPrice: true,
								cf: true,
								boxes: true,
								furniture: true,
							})
						);
						dispatch(
							updateMovePlan({
								...getState().common.dummyPayloadForMovePlan,
							})
						);
					} else {
						dispatch(getCurrentMoveplan());
					}

					break;
				default:
					break;
			}
		}
		if (action.type === NETWORK_FAILURE) {
			switch (action.meta.originalRequest.fromAction) {
				case GET_PRICING_BREAKDOWN:
					dispatch(setModalLoading(false));
					break;
				default:
					break;
			}
		}
	};

export default compareMiddleware;

// $http.get "#{API_URL}/move_plans/#{MovePlan.uuid()}/movers"
