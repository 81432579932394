import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SocialBar = props => {
	const { sender } = props;
	return (
		<div
			className={
				sender === 'move-plan-footer'
					? `d-flex justify-center position-relative`
					: 'position-relative'
			}>
			<ul
				className="social-links mb-0 d-flex"
				style={{ flexDirection: 'row-reverse' }}>
				<li className="social-item">
					<a
						className="social-link"
						href="https://twitter.com/unpakt"
						target="_blank"
						rel="noopener noreferrer">
						<LazyLoadImage
							height={49}
							width={48}
							src={`${process.env.REACT_APP_CDN_IMAGES}/Twitter.png`}
							alt="twitter"
						/>
					</a>
				</li>
				<li className="social-item">
					<a
						className="social-link"
						href="https://www.facebook.com/Unpakt"
						target="_blank"
						rel="noopener noreferrer">
						<LazyLoadImage
							height={49}
							width={48}
							src={`${process.env.REACT_APP_CDN_IMAGES}/FB.png`}
							alt="facebook"
						/>
					</a>
				</li>
				<li className="social-item">
					<a
						className="social-link"
						href="https://www.youtube.com/user/GetUnpakt"
						target="_blank"
						rel="noopener noreferrer">
						{' '}
						<LazyLoadImage
							height={49}
							width={48}
							src={`${process.env.REACT_APP_CDN_IMAGES}/Youtube.png`}
							alt="youtube"
						/>
					</a>
				</li>
			</ul>

			{!sender && (
				<a
					className="bbb"
					href="https://www.bbb.org/us/ny/bronx/profile/directory-advertising/unpakt-llc-0121-139111#bbbonlineclick"
					target="_blank"
					rel="noopener noreferrer">
					<LazyLoadImage
						height={28}
						className="logo bbb"
						src={`${process.env.REACT_APP_CDN_IMAGES}/trustLogo_1.png`}
						alt="bbb logo"
						style={{ display: 'inline-block' }}
					/>
				</a>
			)}

			<style jsx>{`
        .social-links {
          text-align: right;
        }
        .social-item {
          display: inline-block;
          width: auto;
          padding: 0.7rem;
          vertical-align: middle;
        }
        .bbb {
          position: absolute;
          right: 35px;
          top: 60px;
          background: #042b3b;
          border-radius: 7px;
        }
        div.position-relative {
          text-align: right;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px){
          .bbb {
            right:10px;
            top:60px;
          }
      `}</style>
		</div>
	);
};

export default SocialBar;
