import { apiRequest } from '../actions/api';
import {
	FETCH_ONSITE_REQUESTS,
	GET_POTENTIAL_MOVERS,
	NETWORK_SUCCESS,
	SEND_CONSULTATION_REQUEST,
} from '../actionTypes';
import {
	setNoMoversFound,
	setPotentialMovers,
	setOnsiteRequests,
	setConsultationsLoading,
} from '../actions/consultations';
import { push } from 'connected-react-router';
import has from 'lodash-es/has';
import { format } from 'date-fns';

const consultationsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		const movePlanMPID =
			getState().movePlan.currentMPID || getState().movePlan.dummyMPID;

		if (action.type === FETCH_ONSITE_REQUESTS) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${movePlanMPID}/onsite_requests`,
					method: 'GET',
					fromAction: {
						type: FETCH_ONSITE_REQUESTS,
					},
				})
			);
		}
		if (action.type === GET_POTENTIAL_MOVERS) {
			let loading = [false, false, false];
			loading[action.payload.index] = true;
			dispatch(setConsultationsLoading(loading));
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${movePlanMPID}/onsite_requests/potential_movers`,
					method: 'GET',
					params: {
						type: getState().consultations.type,
						date: format(
							new Date(
								getState().consultations.options[action.payload.index].date
							),
							'YYYY-MM-DD'
						),
						time: getState().consultations.options[action.payload.index].time,
					},
					fromAction: {
						type: GET_POTENTIAL_MOVERS,
						payload: action.payload,
					},
				})
			);
		}

		if (action.type === SEND_CONSULTATION_REQUEST) {
			if (has(getState().auth, 'user')) {
				let payload = getState().consultations.options.map(request => {
					if (request.mover) {
						return {
							mover_id: request.mover.id,
							name: getState().consultations.contact.name,
							phone_number: getState().consultations.contact.phone_number,
							type: getState().consultations.type,
							date: format(request.date, 'MM-DD-YYYY'),
							time: request.time,
						};
					} else return null;
				});
				dispatch(
					apiRequest({
						url: `api/v3/move_plans/${movePlanMPID}/onsite_requests/create_for_movers?rnd=5259-cdaf-9ab8-9352`,
						method: 'POST',
						params: {
							onsite_requests: payload.filter(item => !!item),
						},
						fromAction: {
							type: SEND_CONSULTATION_REQUEST,
						},
					})
				);
			} else {
				dispatch(push(`/moving/${movePlanMPID}/consultation/sign-up`));
			}
		}
		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction.type) {
				case FETCH_ONSITE_REQUESTS:
					dispatch(
						setOnsiteRequests(action.payload.response.data.onsite_requests)
					);
					break;
				case GET_POTENTIAL_MOVERS:
					const index = action.meta.originalRequest.fromAction.payload.index;
					if (action.payload.response.data.movers.length === 0) {
						dispatch(setNoMoversFound(index, true));
					} else {
						dispatch(setNoMoversFound(index, false));
					}
					dispatch(
						setPotentialMovers(index, action.payload.response.data.movers)
					);
					dispatch(setConsultationsLoading([false, false, false]));
					break;
				case SEND_CONSULTATION_REQUEST:
					dispatch(push(`/moving/${movePlanMPID}/consultations/success`));
					break;
				default:
					break;
			}
		}
	};

export default consultationsMiddleware;
