import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useMetaNoFollow, useTagManager } from './components/common/Hooks';
import { Redirect } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import './style/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/general.css';
import AnalyticsWrapper from './components/common/AnalyticsWrapper';
import { checkIsMobile } from './redux/actions/ui/mobile';
import { checkIsApp } from './redux/actions/auth';
import LoadingPage from './components/common/LoadingPage';
import { JSON_LD_DATA } from './constants';
import FullWidthLayout from './components/layouts/FullWidthLayout';

const AsyncModals = lazy(() => import('./components/modals/Modals'));
const AsyncMovingLayout = lazy(() => import('./components/layouts/Moving'));
const AsyncBidsLayout = lazy(() => import('./components/layouts/Bids'));
const AsyncMovingLayoutModal = lazy(() =>
	import('./components/layoutsModal/Moving')
);
const AsyncAffiliatesWidget = lazy(() =>
	import('./components/common/SidebarDna/DnaForAffiliates')
);

const mapStateToProps = ({ auth }) => ({
	isAdmin: auth.isAdmin,
});

const App = props => {
	const [isAdmin, setIsAdmin] = useState(props.isAdmin);
	useMetaNoFollow();
	useTagManager();

	useEffect(() => {
		setIsAdmin(props.isAdmin);
	}, [props.isAdmin]);

	useEffect(() => {
		props.checkIsMobile();
		props.checkIsApp();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let badting = localStorage.getItem('ngStorage-lastUUID');
		if (!!badting) localStorage.removeItem('ngStorage-lastUUID');
		return () => {
			try {
				localStorage.removeItem('persist:root');
			} catch (e) {
				return false;
			}
		};
	}, []);

	return (
		<AnalyticsWrapper>
			<Helmet>
				<meta
					name="description"
					content="Save time and money! Instantly compare moving quotes and guaranteed prices from the best moving companies online, with real customer reviews."
				/>
				<meta name="robots" content="index" />
				<link rel="canonical" href="https://www.unpakt.com/" />
				<script type="application/ld+json">
					{JSON.stringify(JSON_LD_DATA)}
				</script>
				<script
					defer
					async
					src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}></script>

				{isAdmin && (
					<script
						type="text/javascript"
						src="https://luminanyc.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=67c2f0f7"></script>
				)}
			</Helmet>
			<div>
				<div className="font-proxima">
					<Suspense fallback={null}>
						<AsyncModals />
					</Suspense>
					<Switch>
						<Route
							path="/moving/:movingPlanId"
							render={() => (
								<Suspense fallback={null}>
									<AsyncMovingLayout />
								</Suspense>
							)}
						/>
						<Route
							path="/movingbids/:movingPlanId"
							render={() => (
								<Suspense fallback={null}>
									<AsyncBidsLayout />
								</Suspense>
							)}
						/>
						<Route
							path="/modal/moving/:movingPlanId"
							render={() => (
								<Suspense fallback={null}>
									<AsyncMovingLayoutModal />
								</Suspense>
							)}
						/>
						<Route
							path="/view_move_plan/:movePlanId"
							render={({ location }) => (
								<Redirect to={`/moving/${location.pathname.slice(16)}/plan`} />
							)}
						/>
						<Route
							path="/blog/"
							component={() => {
								if (window.location.pathname.length > 5)
									return (window.location.href =
										'https://blog.unpakt.com' +
										window.location.pathname.slice(5));
								else return (window.location.href = 'https://blog.unpakt.com');
							}}
						/>
						<Route
							exact
							path="/affiliates-widget/"
							render={() => (
								<Suspense fallback={<LoadingPage />}>
									<AsyncAffiliatesWidget />
								</Suspense>
							)}
						/>
						<Route path="/" component={FullWidthLayout} />
					</Switch>
				</div>
			</div>
		</AnalyticsWrapper>
	);
};

export default withRouter(
	connect(mapStateToProps, { checkIsMobile, checkIsApp })(App)
);
