import { LOAD_FACEBOOK, LOAD_TWITTER, SHARE } from '../actionTypes';

export const loadTwitter = () => ({
	type: LOAD_TWITTER,
});

export const loadFacebook = () => ({
	type: LOAD_FACEBOOK,
});

export const share = type => ({
	type: SHARE,
	payload: type,
});
