import {
	API_REQUEST,
	NETWORK_FAILURE,
	NETWORK_PENDING,
	NETWORK_SUCCESS,
	UPLOAD_FILE_REQUEST,
} from '../actionTypes';

export const apiRequest = ({
	url,
	method,
	params,
	fromAction,
	redirect,
	callback,
	file,
}) => ({
	type: API_REQUEST,
	payload: { url, method, params },
	meta: { fromAction, redirect, callback, file },
});

export const uploadFileRequest = ({
	url,
	method,
	params,
	fromAction,
	redirect,
	callback,
	file,
}) => ({
	type: UPLOAD_FILE_REQUEST,
	payload: { url, method, params },
	meta: { fromAction, redirect, callback, file },
});

export const networkPending = ({ originalRequest }) => ({
	type: NETWORK_PENDING,
	meta: { originalRequest },
});

export const networkSuccess = ({ response, originalRequest }) => ({
	type: NETWORK_SUCCESS,
	payload: { response },
	meta: { originalRequest },
});

export const networkFailure = ({ response, originalRequest }) => ({
	type: NETWORK_FAILURE,
	payload: { response },
	meta: { originalRequest },
});
