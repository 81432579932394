import { useEffect } from 'react';

const loadGTM = gtmId => {
	// Add the GTM script in the <head> tag
	(function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			// eslint-disable-next-line
			dl = l != 'dataLayer' ? '&l=' + l : '';
		j.async = true;
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer', gtmId);

	// Add the <noscript> GTM iframe just after <body>
	const iframe = document.createElement('iframe');
	iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
	iframe.height = 0;
	iframe.width = 0;
	iframe.style = 'display:none;visibility:hidden';
	document.body.insertBefore(iframe, document.body.firstChild);
};

const initAnalytics = () => {
	// Initialize the GTM dataLayer
	window.dataLayer = window.dataLayer || [];

	// Function to track page views via GTM dataLayer
	const trackPageView = (pageName, path) => {
		window.dataLayer.push({
			event: 'pageview', // Custom event for GTM
			pageName: pageName,
			pagePath: path || window.location.pathname,
		});
	};

	if (
		window.location.pathname.includes('/moving/') ||
		window.location.pathname.includes('/movingbids/')
	) {
		let temp = window.location.pathname.split('/');
		let path = '/' + temp[1] + '/' + temp[3];
		trackPageView(temp[3], path);
	} else {
		trackPageView(window.location.pathname);
	}
};

const AnalyticsWrapper = props => {
	useEffect(() => {
		if (window.location.pathname.includes('affiliates-widget')) return;

		loadGTM(process.env.REACT_APP_GTM_ID);

		let t = setTimeout(() => {
			window.onload = initAnalytics();
		}, 5000);

		return () => clearTimeout(t);
	}, []);

	return props.children;
};

export default AnalyticsWrapper;
