import {
	GET_UTM_PARAMS,
	NETWORK_FAILURE,
	TRACK_TO_SEGMENT,
} from '../actionTypes';
import { setUtmParams, track } from '../actions/analytics';
import { setCouponData } from '../actions/analytics';
import { LOCATION_CHANGE } from 'connected-react-router';

const analyticsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		// Initialize GTM dataLayer if it doesn't exist
		window.dataLayer = window.dataLayer || [];

		if (action.type === GET_UTM_PARAMS) {
			if (getState().router.location.search.length > 0) {
				let params = JSON.parse(
					'{"' +
						decodeURI(getState().router.location.search.substring(1))
							.replace(/"/g, '\\"')
							.replace(/&/g, '","')
							.replace(/=/g, '":"') +
						'"}'
				);
				dispatch(setUtmParams(params));

				// Dispatch the track action to push events to GTM dataLayer
				if (!!params.gclid) {
					dispatch(track('Landed from Google paid', { gclid: params.gclid }));
				}
				if (!!params.fcblid) {
					dispatch(
						track('Landed from Facebook paid', { fcblid: params.fcblid })
					);
				}
				if (!!params.promo) {
					dispatch(setCouponData({ code: params.promo }));
				}
			}
		}

		if (action.type === LOCATION_CHANGE) {
			// Use the track action to push page view events to GTM dataLayer
			if (
				window.location.pathname.includes('/moving/') ||
				window.location.pathname.includes('/movingbids/')
			) {
				let temp = window.location.pathname.split('/');
				let path = '/' + temp[1] + '/' + temp[3];
				dispatch(track('pageview', { pageName: temp[3], pagePath: path }));
			} else {
				dispatch(track('pageview', { pagePath: window.location.pathname }));
			}
		}

		if (action.type === NETWORK_FAILURE) {
			// Use the track action to push network failure event to GTM dataLayer
			dispatch(track('NETWORK FAILURE', action));
		}

		if (action.type === TRACK_TO_SEGMENT) {
			window.dataLayer.push({
				event: action.payload.event,
				...action.payload.data,
			});
		}
	};

export default analyticsMiddleware;
