import React, { useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import Footer from '../common/Footer/Footer';
import { setLastMPID } from '../../redux/actions/auth';
import { getUtmParams } from '../../redux/actions/analytics';
import { useTimeout } from '../common/Hooks';
import { setCouponData } from '../../redux/actions/analytics';
import { withRouter } from 'react-router';

const AsyncFeaturesConcierge = lazy(() => import('./FeaturesConcierge'));
const AsyncHowItWorksConcierge = lazy(() =>
	import('../cityPages/HowItWorksCityPageConcierge')
);
const AsyncNews = lazy(() => import('./News'));
const AsyncNumbers = lazy(() => import('./Numbers'));
const AsyncServices = lazy(() => import('./Services'));
const AsyncReferralSection = lazy(() => import('./ReferralSection'));
const AsyncCoverConcierge = lazy(() => import('./CoverConcierge'));

const Landing = ({
	setLastMPID,
	viewModel,
	getUtmParams,
	match,
	setCouponData,
}) => {
	useEffect(() => {}, [setLastMPID]);

	useEffect(() => {
		switch (match.params.partner) {
			case 'equiem':
				setCouponData({ code: 'EQUIEM10' });
				break;
			case 'nfcamenity':
				setCouponData({ code: 'NFCAMENITY5' });
				break;
			default:
				break;
		} //eslint-disable-next-line
	}, [match]);

	useTimeout(() => getUtmParams(), 3500);
	return (
		viewModel !== '' && (
			<div className="landing-container">
				<div id="main-cover" className={`scroll home-content-${viewModel}`}>
					<Suspense fallback={''}>
						<AsyncCoverConcierge
							viewModel={viewModel}
							partner={match.params.partner}
						/>
					</Suspense>
				</div>
				<div className="lower-section">
					<Suspense fallback={''}>
						<AsyncNumbers viewModel={viewModel} />
					</Suspense>
					<Suspense fallback={''}>
						<AsyncFeaturesConcierge viewModel={viewModel} />
					</Suspense>
					<div className="bg-white">
						<div className="container">
							<Suspense fallback={''}>
								<AsyncNews viewModel={viewModel} />
							</Suspense>
						</div>
						<Suspense fallback={''}>
							<AsyncHowItWorksConcierge
								viewModel={viewModel}
								hideButton={true}
							/>
						</Suspense>
						<Suspense fallback={''}>
							<AsyncServices viewModel={viewModel} />
						</Suspense>
						<Suspense fallback={''}>
							<AsyncReferralSection viewModel={viewModel} />
						</Suspense>
						<Footer />
					</div>
				</div>
				<style jsx>{`
					.landing-container {
						background: #5cbed9;
					}
					#main-cover {
						overflow: visible !important;
					}
					.scroll {
						overflow-y: auto;
					}
					.lower-section {
						position: relative;
					}
					:global(canvas) {
						display: ${viewModel === 'wide' || viewModel === 'desktop'
							? 'block'
							: 'none'};
					}
					:global(#movinghubMainDivx iframe) {
						min-height: 250px;
						border-radius: 5px;
					}
				`}</style>
			</div>
		)
	);
};

export default connect(null, { setLastMPID, getUtmParams, setCouponData })(
	withRouter(Landing)
);
