import {
	CHECK_EMAIL_AVAILABILITY,
	SET_EMAIL_TAKEN,
	REGISTER_USER,
	SIGN_IN,
	SIGN_OUT,
	SET_USER,
	SET_ERROR_MSG,
	FACEBOOK_LOGIN,
	GOOGLE_LOGIN,
	UPDATE_FACEBOOK_AUTH,
	SET_AUTH_MODAL_VIEW,
	SET_AUTH_SUBMITTING,
	UNSET_USER,
	CHECK_COOKIES,
	SET_ROLE,
	RESET_PASSWORD,
	SET_RESET_EMAIL,
	SET_LAST_MPID,
	CHECK_IS_APP,
	SET_IS_APP,
} from '../actionTypes';

export const setRole = (role, bool) => ({
	type: SET_ROLE,
	payload: { role, bool },
});

export const checkEmailAvailability = email => ({
	type: CHECK_EMAIL_AVAILABILITY,
	payload: email,
});

export const setEmailTaken = bool => ({
	type: SET_EMAIL_TAKEN,
	payload: bool,
});

export const registerUser = (user, meta) => ({
	type: REGISTER_USER,
	payload: user,
	meta: !!meta ? meta : {},
});

export const setUser = user => ({
	type: SET_USER,
	payload: user,
});

export const unsetUser = () => ({
	type: UNSET_USER,
});

export const signIn = (user, meta) => ({
	type: SIGN_IN,
	payload: user,
	meta: !!meta ? meta : {},
});

export const signOut = () => ({
	type: SIGN_OUT,
});

export const setErrorMessage = error => ({
	type: SET_ERROR_MSG,
	payload: error,
});

export const facebookLogin = fromBooking => ({
	type: FACEBOOK_LOGIN,
	payload: fromBooking,
});

export const updateFacebookAuth = update => ({
	type: UPDATE_FACEBOOK_AUTH,
	payload: update,
});

export const googleLogin = () => ({
	type: GOOGLE_LOGIN,
});

export const setAuthModalView = view => ({
	type: SET_AUTH_MODAL_VIEW,
	payload: view,
});

export const setAuthSubmitting = bool => ({
	type: SET_AUTH_SUBMITTING,
	payload: bool,
});

export const checkCookies = cookies => ({
	type: CHECK_COOKIES,
	payload: cookies,
});

export const resetPassword = email => ({
	type: RESET_PASSWORD,
	payload: email,
});

export const setResetEmail = email => ({
	type: SET_RESET_EMAIL,
	payload: email,
});

export const setLastMPID = mpid => ({
	type: SET_LAST_MPID,
	payload: mpid,
});

export const checkIsApp = () => ({
	type: CHECK_IS_APP,
});

export const setIsApp = bool => ({
	type: SET_IS_APP,
	payload: bool,
});
