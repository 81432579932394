import {
	DELETE_PLAN,
	EMAIL_CHECKLIST,
	GET_ALL_PLANS,
	INIT_MYHOME,
	PRINT_CHECKLIST,
	REUSE_PLAN,
	GET_CITY_DATA_FOR_HOME_PAGE,
	SET_ALL_PLANS,
	BOOK_PLAN,
	SET_CITY_DATA_FOR_HOME_PAGE,
	START_NEW_PLAN,
	INIT_HOME_COMPARE_PAGE,
	VIEW_PLAN,
	GET_HOMEPAGE_ESTIMATES,
} from '../actionTypes';

export const initMyHome = () => ({
	type: INIT_MYHOME,
});

export const getHomepageEstimates = () => ({
	type: GET_HOMEPAGE_ESTIMATES,
});

export const initHomeCompare = () => ({
	type: INIT_HOME_COMPARE_PAGE,
});

export const getCityDataForHomePage = () => ({
	type: GET_CITY_DATA_FOR_HOME_PAGE,
});

export const setCityDataForHomePage = cityData => ({
	type: SET_CITY_DATA_FOR_HOME_PAGE,
	payload: cityData,
});

export const emailChecklist = () => ({
	type: EMAIL_CHECKLIST,
});

export const setAllPlans = planData => ({
	type: SET_ALL_PLANS,
	payload: planData,
});

export const getAllPlans = () => ({
	type: GET_ALL_PLANS,
});

export const startNewPlan = () => ({
	type: START_NEW_PLAN,
});

export const printChecklist = () => ({
	type: PRINT_CHECKLIST,
});

export const reusePlan = (uuid, move_plan) => ({
	type: REUSE_PLAN,
	payload: { uuid: uuid, move_plan: move_plan },
});

export const viewPlan = (plan, status) => ({
	type: VIEW_PLAN,
	payload: { plan, status },
});

export const deletePlan = index => ({
	type: DELETE_PLAN,
	payload: index,
});

export const bookPlan = uuid => ({
	type: BOOK_PLAN,
	payload: { uuid: uuid },
});
