import React from 'react';

export const nearByCities = [
	{
		label: 'Atlanta, GA',
		link: `/moving-companies/GA/atlanta`,
	},
	{
		label: 'Denver, CO',
		link: `/moving-companies/CO/denver`,
	},
	{
		label: 'Nashville, TN',
		link: '/moving-companies/TN/nashville',
	},
	{
		label: 'Portland, OR',
		link: `/moving-companies/OR/portland`,
	},
	{
		label: 'Austin, TX',
		link: `/moving-companies/TX/austin`,
	},
	{
		label: 'Houston, TX',
		link: `/moving-companies/TX/houston`,
	},
	{
		label: 'New Orleans, LA',
		link: `/moving-companies/LA/new-orleans`,
	},
	{
		label: 'San Antonio, TX',
		link: '/moving-companies/TX/san-antonio',
	},
	{
		label: 'Boston, MA',
		link: '/moving-companies/MA/boston',
	},
	{
		label: 'Las Vegas, NV',
		link: '/moving-companies/NV/las-vegas',
	},
	{
		label: 'New York, NY',
		link: `/moving-companies/NY/new-york`,
	},
	{
		label: 'San Diego, CA',
		link: `/moving-companies/CA/san-diego`,
	},
	{
		label: 'Chicago, IL',
		link: `/moving-companies/IL/chicago`,
	},
	{
		label: 'Los Angeles, CA',
		link: `/moving-companies/CA/los-angeles`,
	},
	{
		label: 'Phoenix, AZ',
		link: `/moving-companies/AZ/phoenix`,
	},
	{
		label: 'San Francisco, CA',
		link: `/moving-companies/CA/san-francisco`,
	},
	{
		label: 'Dallas, TX',
		link: `/moving-companies/TX/dallas`,
	},
	{
		label: 'Miami, FL',
		link: `/moving-companies/FL/miami`,
	},
	{
		label: 'Philadelphia, PA',
		link: '/moving-companies/PA/philadelphia',
	},
	{
		label: 'Seattle, WA',
		link: `/moving-companies/WA/seattle`,
	},
];
export const cityDescription = {
	NYC: `Unpakt has revolutionized the way you plan and book moves. Explore our selection of the best moving companies in New York and compare services and quotes. With Unpakt's platform, seamlessly plan and book your move with flexibility and ease.`,

	Chicago: `Unpakt will help you move in Chicago. Browse our extensive network of the best movers in the city while comparing services and quotes. Effortlessly plan and book your move with our easy-to-use platform!`,

	Cambridge: `Discover the smoothest way to move in Cambridge with Unpakt's exclusive network of trusted and reliable movers. Our carefully selected list of top-rated moving experts is ready to assist you with all your moving needs. Effortlessly manage your move, compare moving companies, and more!`,

	Miami: `At Unpakt, we know the best Miami movers and can connect you with the best. Please take a moment to browse a few of our featured Miami movers so that you can begin comparing reviews and quotes instantly!`,

	SF: `Unpakt can assist you with a hassle-free relocation in San Francisco. Our platform is tailor-made to simplify your search for movers their services and gives you the ability to compare quotes and reviews.`,
};

export const posts = {
	LA: [
		{
			link: 'https://blog.unpakt.com/moving-from-nyc-to-la-5-things-to-consider/',
			title: 'Moving From NYC to LA: 5 Things to Consider',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/10/woman-in-winter-clothes-at-beach-e1634222167670.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/moving-to-la-or-nyc-check-our-comparative-guide/',
			title: 'Moving to LA or NYC? Check Our Comparative Guide',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/02/shutterstock_57571180-e1613085440236-810x573.jpg',
		},
		{
			link: 'https://blog.unpakt.com/get-a-fresh-start-in-these-trending-los-angeles-neighborhoods/',
			title: 'Get a Fresh Start in These Trending Los Angeles Neighborhoods',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2020/07/nathan-dumlao-xxzeV1aiSY8-unsplash-2.jpg',
		},
		{
			link: 'https://blog.unpakt.com/things-you-didnt-know-about-los-angeles/',
			title: 'Things You Didn’t Know About Los Angeles',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2019/12/Los-Angeles-ah5NgkQlFoI-howie-mapson-unsplash-1536x851.jpg',
		},
		{
			link: 'https://blog.unpakt.com/how-much-it-cost-to-move-in-los-angeles/',
			title: 'How Much Does it Cost to Move Locally in Los Angeles?',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/09/Los-Angeles-47130674_l-LOS-ANGELES-OCTOBER-15-2015-View-of-Hollywood-Boulevard-by-night-1140x761.jpg',
		},
		{
			link: 'https://blog.unpakt.com/top5-reasons-why-moving-to-los-angeles-is-exciting/',
			title: 'Top 5 Reasons Why Moving to Los Angeles is Exciting',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2014/04/Los-Angeles-31390767_xxl-A-view-of-the-Santa-Monica-Beach-just-before-the-sun-goes-down-for-the-evening_result_result.jpg',
		},
	],
	NYC: [
		{
			link: 'https://blog.unpakt.com/how-to-make-a-small-move-in-new-york-with-expert-movers/',
			title: 'How to Make a Small Move in New York with Expert Movers',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2022/02/miniature-moving-men-and-boxes-e1646330515762.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/best-new-york-neighborhoods-to-live-in-2021/',
			title: 'Best New York Neighborhoods to Live in 2021',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/08/iconic-manhattan-skyline-e1628023930324.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/3-tips-for-making-a-small-move-in-new-york-with-expert-movers/',
			title: '3 Tips for Making a Small Move in New York with Expert Movers',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/06/movers-with-two-boxes-e1624310872362.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/things-to-consider-when-hiring-a-new-york-city-mover/',
			title: 'Things to Consider When Hiring a New York City Mover',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/04/new-york-city-street-e1620689470854.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/moving-services-in-new-york-expert-tips-for-a-smooth-move/',
			title: 'Moving Services in New York: Expert tips for a smooth move',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2020/12/new-york-movers-1.jpg',
		},
		{
			link: 'https://blog.unpakt.com/why-you-should-live-in-upstate-new-york/',
			title: 'Why You Should Live in Upstate New York',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2020/06/yassine-khalfalli-nfkFSmjtfMQ-unsplash-1536x862.jpg',
		},
	],
	Miami: [
		{
			link: 'https://blog.unpakt.com/best-miami-neighborhoods-to-live-in-2021/',
			title: 'Best Miami Neighborhoods to Live in 2021',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/11/miami-aerial-e1636479877205.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/how-much-it-cost-to-move-in-miami/',
			title: 'How Much Does it Cost to Move Locally in Miami?',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2017/03/Miami-Beach-36857477_l-luxury-houses-at-the-canal-in-Miami-Beach-with-boats.jpg',
		},
		{
			link: 'https://blog.unpakt.com/why-living-in-miami-beach-is-better-than-in-miami/',
			title: 'Why Living in Miami Beach is Better Than in Miami',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/11/Miami-53263141_l-Wonderful-skyline-of-Miami-at-sunset-aerial-view-1140x658.jpg',
		},
		{
			link: 'https://blog.unpakt.com/top-10-things-to-do-in-miami-beach/',
			title: 'Top 10 Things to Do in Miami Beach',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/11/Miam-Beach-10887208_l-MIAMI-BEACH-USA-JULY-31midday-view-at-Ocean-drive-on-July-312010-in-Miami-Beach-USA.-Art-Deco-architecture-in-South-Beach-is-one-of-the-main-tourist-attractions-in-Miam-1140x760.jpg',
		},
		{
			link: 'https://blog.unpakt.com/where-should-you-live-in-miami-beach/',
			title: 'Where Should You Live In Miami Beach?',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/11/Miami-Beach-21734844_xxl-Miami-Beach-Florida-hotels-and-restaurants-at-sunset-on-Ocean-Drive-world-famous-destination-for-its-nightlife-beautiful-weather-and-pristine-beaches_result_result.jpg',
		},
		{
			link: 'https://blog.unpakt.com/cost-of-living-in-miami-beach/',
			title: 'The Cost of Living in Miami Beach',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/11/Miami-Beach-19106932_l-City-of-Miami-Beach-Florida-welcome-sign-with-palm-trees-on-a-sunny-summer-day-1140x742.jpg',
		},
	],
	SF: [
		{
			link: 'https://blog.unpakt.com/how-much-it-cost-to-move-in-san-francisco/',
			title: 'How Much Does it Cost to Move Locally in San Francisco?',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/08/San-Francisco-12941895_l-San-Francisco-city-landmarks-and-tourist-destinations-collage.jpg',
		},
		{
			link: 'https://blog.unpakt.com/best-san-francisco-neighborhoods-to-live-in-2021/',
			title: 'Best San Francisco Neighborhoods to Live In 2021',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2021/08/san-francisco-painted-ladies-e1629919290199.jpeg',
		},
		{
			link: 'https://blog.unpakt.com/leaving-san-francisco-try-moving-to-the-suburbs-instead/',
			title: 'Leaving San Francisco? Try Moving to the Suburbs Instead',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2020/07/golden-gate-bridge-534614_1280.jpg',
		},
		{
			link: 'https://blog.unpakt.com/interesting-facts-about-san-francisco/',
			title: 'Interesting Facts About San Francisco',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2019/09/San-Francisco-6ccJQ5qPFvY-edgar-chaparro-unsplash-e1568000281705.jpg',
		},
		{
			link: 'https://blog.unpakt.com/the-romantic-summer-guide-to-san-francisco/',
			title: 'The Romantic Summer Guide to San Francisco',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2017/07/San-Francisco-18730944_l-San-Francisco-happy-people-tourist-couple-at-Golden-Gate-Bridge.-Young-attractive-modern-couple-cheering-happy-excited-and-joyful.jpg',
		},
		{
			link: 'https://blog.unpakt.com/why-living-in-oakland-is-better-than-living-in-san-francisco/',
			title: 'Why Living in Oakland is Better than Living in San Francisco',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/07/25724584_xxl-San-Francisco-Oakland-Bay-Bridge-at-night-USA-e1612818908521.jpg',
		},
	],
	CHI: [
		{
			link: 'https://blog.unpakt.com/weekend-getaways-chicago/',
			title: 'Best Weekend Getaways from Chicago',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2019/09/O7qK1vQY3p0-grant-porter-unsplash-e1567998768797.jpg',
		},
		{
			link: 'https://blog.unpakt.com/fun-unique-date-night-ideas-chicago/',
			title: '6 Fun and Unique Date Night Ideas in Chicago',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2019/03/1140342-brandi-ibrao-unsplash-e1552339263252.jpg',
		},
		{
			link: 'https://blog.unpakt.com/much-cost-move-chicago/',
			title: 'How Much Does it Cost to Move in Chicago?',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/06/Chicago-14143040_l-CHICAGO-IL-Oct-6-Chicago-Theatre-interior-on-October-6-2011-in-Chicago-Illinois-1140x759.jpg',
		},
		{
			link: 'https://blog.unpakt.com/tips-professional-networking-chicago/',
			title: 'Tips for Professional Networking in Chicago',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/03/38817744_l-business-people-international-communication-headhunting-and-technology-concept-1140x812.jpg',
		},
		{
			link: 'https://blog.unpakt.com/5-best-public-school-districts-in-chicago/',
			title: '5 Best Public School Districts in Chicago',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/03/46212696_l-Cute-pupils-smiling-at-camera-in-the-school-bus-outside-the-elementary-school-1140x760.jpg',
		},
		{
			link: 'https://blog.unpakt.com/5-must-know-tips-for-house-hunting-in-chicago/',
			title: '5 Must-Know Tips for House Hunting in Chicago',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/03/Chicago-21221143_l-Suburban-neighborhood-in-South-Side-of-Chicago-horizontal-1140x763.jpg',
		},
	],
	Camb: [
		{
			link: 'https://blog.unpakt.com/the-best-underground-bars-in-cambridge/',
			title: 'The Best Underground Bars in Cambridge',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/06/71471745_l-Group-of-young-people-celebrating-at-pub.-Friends-toasting-cocktails-in-night-club-1140x652.jpg',
		},
		{
			link: 'https://blog.unpakt.com/the-best-8-activities-to-do-in-cambridge/',
			title: 'The Best 8 Activities to Do in Cambridge',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2016/06/31563077_xxl-An-Gorta-Mor-The-Great-Hunger-Memorial-in-Cambridge-MA-e1514621398953.jpg',
		},
		{
			link: 'https://blog.unpakt.com/millennial-guide-to-boston/',
			title: 'Millennial’s Guide to Boston',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2019/10/Boston-3IVH-_Jc91o_michael-browning-unsplash-e1570393944641.jpg',
		},
		{
			link: 'https://blog.unpakt.com/top-yoga-studios-boston/',
			title: 'Top 9 Yoga Studios to Check Out in Boston',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2019/02/bruce-mars-558921-unsplash-e1549659649807.jpg',
		},
		{
			link: 'https://blog.unpakt.com/how-to-obtain-moving-permits/',
			title: 'How to Obtain Moving Permits',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2018/08/18011553_xl-Empty-moving-truck-with-loading-ramp-at-Rome-street-e1534787491737.jpg',
		},
		{
			link: 'https://blog.unpakt.com/the-romantic-guide-to-boston/',
			title: 'The Romantic Guide to Boston',
			image:
				'https://blog.unpakt.com/wp-content/uploads/2017/09/Boston-8793321_l-Boston-Common-and-Public-Garden-USA-1140x760.jpg',
		},
	],
};

export const faqs = {
	LA: [
		{
			question: 'When is the cheapest time to move in Los Angeles, CA?',
			answer: `Try to avoid moving on the busiest days of the month (1st, 15th, 30th, and 31st) as those days tend to be the most expensive, additionally, booking in advance will help you save. We recommend booking at least 2 weeks in advance in the spring, fall and winter, and at least 4 weeks in the summer. It’s also great to be handy - get boxes and pack and unpack things yourself to save on additional service costs. Lastly, prices are usually based on your inventory, to lower the price, consider editing your inventory to the essentials.`,
		},
		{
			question: 'What is the average cost of moving in LA?',
			answer: `Obviously, prices depend greatly on your pick-up and drop-off locations. For example, moving from NY to LA or the other way around will cost you an average of $2586 or $2863, respectively. Naturally, prices may be higher or lower than this average. Keep in mind that reliability and professionalism often come at a price in the moving business, so cheap long distance moving companies may not be your best choice.`,
		},
		{
			question:
				'What considerations should I make when choosing a LA moving company?',
			answer: `When you use Unpakt, you never have to worry about the moving company you hire because every company has been thoroughly screened. Unpakt verifies the license, insurance, experience and the reputation of every moving company listed on their site.`,
		},
		{
			question: 'Should you hire professional movers or rent a moving truck?',
			answer: `If you’re not moving farther than 10-15 miles and can perform the entire move within a day, a rental truck may be the perfect solution for you. However, if your inventory is bigger than that or your new home isn’t within said distance from your old one, a moving company will probably be better equipped and prepared to handle your move.`,
		},
		{
			question: 'How many movers will you need?',
			answer: `Simply ask your movers how many movers they are planning on dispatching. Keep in mind that the movers may not set the specifics until the day before your move date when the next day's schedule is completed.`,
		},
	],
	NYC: [
		{
			question: 'When is the best time to move?',
			answer: `New York City movers prices vary based on season and the day of the week. Seasonally, prices are lowest between late fall and early spring. Prices are also lower on week days, due to the popularity of moving on weekends or holidays. When moving to New York, make sure you check with the building manager as there are restrictions on days and times that you are able to move in or out of NYC apartments.`,
		},
		{
			question: 'How much does a NYC mover cost?',
			answer:
				"Prices are based on when you move, how much you move, and how far you're moving. The best way to get an idea of the price of your NYC move is to use Unpakt's free move quote tool.",
		},
		{
			question: 'Can movers help me with packing?',
			answer:
				'Your moving company can do it all for your New York City move. A full-service move means your professional moving team will come to your home, pack all of your things, and transport them to your new home. They can even unpack your boxes and take the materials away, saving you the trouble.',
		},
		{
			question: 'What about large and awkward items?',
			answer:
				"If you're moving to or within NYC with oversized or delicate items like antique furniture or pieces of artwork, discuss these items with your mover. Professional movers will get these items to your new home safely, giving you peace of mind.",
		},
	],
	Miami: [
		{
			question: 'How do I downsize or declutter before moving?',
			answer: `It is best to start by organizing your belongings into categories such as keep, donate, sell and discard. Go through our items and get rid of the ones you don't need or use anymore. Hosting a garage sale is another good way to offload many items at once.`,
		},
		{
			question: 'How can I transport my pets safely during a move?',
			answer: `When moving with pets, it is important to secure them in a carrier or a crate during transportation. Make sure to have food, water, toys, treats, and familiar items your animal may like in order to keep them comfortable.`,
		},
		{
			question: 'Do I need moving insurance?',
			answer: `Moving insurance is not mandatory, but it is highly recommended. Having additional coverage will help safeguard your personal property in the rare event of damage or loss.`,
		},
		{
			question: 'How much does a move cost in Miami?',
			answer: `On average, if you are moving locally in Miami, a moving company will cost around $300-$1600. A long-distance move can cost anywhere from $1200-$5000 or more. Before booking a moving company, make sure to compare rates and services to get the best deal on local or long-distance moves.`,
		},
	],
	SF: [
		{
			question: 'How do I estimate the weight of my household?',
			answer: `Multiply each room with furniture by 1500 pounds. A professional moving company will be able to more accurately estimate the weight of your household due to their experience and resources.`,
		},
		{
			question: 'How much do full service movers cost in San Francisco?',
			answer: `Hiring full service movers in San Francisco will cost anywhere from $1,500-$5,000 or more for a local move within the city. This price range depends on the specific aspects of your move, such as inventory, distance, and services needed.`,
		},
		{
			question:
				'Can movers assist with navigating parking and other street regulations during a move in San Francisco since its known for its challenging streets and limited parking?',
			answer: `Absolutely. Moving companies in San Francisco are experienced with navigating the unique challenges of the city's streets and limited parking. They are also familiar with local regulations and can assist with parking reservations or permits necessary to complete a move.`,
		},
		{
			question:
				'What steps should I take to ensure an efficient move considering San Francisco traffic and congestion?',
			answer: `Try to plan your move during off-peak hours and non-rush hours. Make sure to obtain the necessary parking permits and arrangements. Communicate with your moving company before your move about any necessary information, such as access points, your location, and more to try and avoid obstacles. Planning ahead and preparing will help you and your moving company execute the move more efficiently.`,
		},
	],
	CHI: [
		{
			question: 'How much does it cost to move in Chicago?',
			answer: `Generally, hiring a professional moving company in Chicago to move locally will cost around $300-$1500. For a long-distance move, prices range anywhere from $1,000-$5,000 or more. Additional services such as packing and unpacking, as well as, storage solutions are typically an additional fee. The cost of moving to Chicago is dependent on your specific move size, distance, and complexity.`,
		},
		{
			question: 'When is the best time to move to Chicago?',
			answer: `The best time to move in Chicago is generally during late spring and early fall seasons. May to June and June to september offer a more mild climate with a lower chance of extreme temperatures. Additionally, this time tends to be less busy for moving companies which can result in more flexibility and lower prices.`,
		},
		{
			question: 'How long does it take to pack for a move?',
			answer: `Packing for a move can depend on how large your space is, as well as, how many things you own. Generally, to pack a one bedroom apartment, it takes about 1-3 days to pack yourself. A two bedroom can take anywhere from 2-5 days. A three bedroom can take anywhere from a 4 days to a week.  Large homes can take about two weeks or longer. Be sure to give yourself enough time to pack properly, and never underestimate the amount of things you own.`,
		},
		{
			question: 'How do I choose a moving company?',
			answer: `When choosing a moving company, it is important to research and compare companies to make an informed decision. Asking for referrals from friends and family members is a great place to start. Research online reviews from verified customers to add to your knowledge of a moving companies reliability. Always ensure a moving company has its proper licensing and insurance to protect your items in case of loss or damage during the move.`,
		},
	],
	Camb: [
		{
			question:
				'Are there any specific regulations or restrictions regarding moving large or bulky items in Cambridge?',
			answer: `Yes, be aware of permits such as oversized vehicle permits, street use, and obstruction permits for large moving trucks. Typically professional moving companies have these specific permits and are allowed to operate.`,
		},
		{
			question:
				'How far in advance should I book a moving company in Cambridge to ensure availability?',
			answer: `It is generally recommended to book a moving company in Cambridge as early as possible. Try to make a booking 4-8 weeks in advance, especially during peak moving seasons, to ensure availability.`,
		},
		{
			question: 'Should I pack my belongings or hire professionals?',
			answer: `This depends on your personal preference, budget, and the complexity of your move. Packing yourself can save you money, but it requires planning, time, effort, and the correct packing materials. Hiring professional movers can streamline the packing process safely and efficiently, minimizing the risk of damages and saving you time.`,
		},
		{
			question: 'How much does a move typically cost in Cambridge?',
			answer: `A local move in Cambridge ranges anywhere from $300-$1500. Moving long distances can cost anywhere from $1,000-$5,000 or more. Moving costs vary depending on the specifics of your move, and additional services such as packing, unpacking, and storage can also add fees to the overall cost of a move.`,
		},
	],
};

export const cityInWebp = [
	'New York',
	'Los Angeles',
	'San Francisco',
	'Miami',
	'Birmingham',
	'Anchorage',
	'Phoenix',
	'Scottsdale',
	'Tempe',
	'Tucson',
	'Little Rock',
	'Fresno',
	'Irvine',
	'Oakland',
	'Sacramento',
	'San Diego',
	'San Jose',
	'Aurora',
	'Colorado Springs',
	'Denver',
	'Hartford',
	'New Haven',
	'Stamford',
	'Washington',
	'Wilmington',
	'Fort Lauderdale',
	'Fort Myers',
	'Jacksonville',
	'Orlando',
	'Palm Beach',
	'Pompano Beach',
	'Tampa',
];

export const cityDetails = {
	'Los Angeles': {
		cityCode: 'LA',
		stateCode: 'CA',
		stateName: 'California',
	},
	'New York': {
		cityCode: 'NYC',
		stateCode: 'NY',
		stateName: 'New York',
	},
	'San Francisco': {
		cityCode: 'SF',
		stateCode: 'CA',
		stateName: 'California',
	},
	Chicago: {
		cityCode: 'CHI',
		stateCode: 'IL',
		stateName: 'Illinois',
	},
	Cambridge: {
		cityCode: 'Camb',
		stateCode: 'MA',
		stateName: 'Massachusetts',
	},
	Miami: {
		cityCode: 'Miami',
		stateCode: 'FL',
		stateName: 'Florida',
	},
};

export const FLOW_STEPS = [
	'INVENTORY',
	'DETAILS',
	'COMPARE',
	'PLAN',
	'BOOK',
	'CONGRATS',
];

export const FLOW_STEPS_BIDS = [
	'INVENTORY',
	'DETAILS',
	'THANKYOU'
];

export const FUNNEL_STEPS = {
	INVENTORY: {
		id: 'inventory',
		title: 'Inventory',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'DETAILS',
		type: 'button',
	},
	DETAILS: {
		id: 'details',
		title: 'Details',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: 'COMPARE',
		type: 'submit',
	},
	COMPARE: {
		id: 'compare',
		title: 'Compare Movers',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: 'BOOK',
		type: 'button',
	},
	BOOK: {
		id: 'book',
		title: 'Book Online',
		step: 'INVENTORY',
		nextStepButtonText: 'Book',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'CONGRATS',
		type: 'submit',
	},
	PLAN: {
		id: 'plan',
		title: 'Review Your Move Plan',
		step: 'PLAN',
		nextStepButtonText: '',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: '',
		type: '',
	},
};

export const FUNNEL_STEPS_BIDS = {
	INVENTORY: {
		id: 'inventory',
		title: 'Inventory',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'DETAILS',
		type: 'button',
	},
	DETAILS: {
		id: 'details',
		title: 'Details',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: 'COMPARE',
		type: 'submit',
	},
	// COMPARE: {
	// 	id: 'compare',
	// 	title: 'Compare Movers',
	// 	step: 'INVENTORY',
	// 	nextStepButtonText: 'continue',
	// 	showNextStepButton: false,
	// 	isCompleted: false,
	// 	nextStep: 'BOOK',
	// 	type: 'button',
	// },
	// BOOK: {
	// 	id: 'book',
	// 	title: 'Book Online',
	// 	step: 'INVENTORY',
	// 	nextStepButtonText: 'Book',
	// 	showNextStepButton: true,
	// 	isCompleted: false,
	// 	nextStep: 'CONGRATS',
	// 	type: 'submit',
	// },
	PLAN: {
		id: 'plan',
		title: 'Review Your Move Plan',
		step: 'PLAN',
		nextStepButtonText: '',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: '',
		type: '',
	},
};

export const HOURLY_STEPS = {
	COMPARE: {
		id: 'compare',
		title: 'Compare Movers',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: 'DETAILS',
		type: 'button',
	},
	DETAILS: {
		id: 'details',
		title: 'Details',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: 'BOOK',
		type: 'submit',
	},
	BOOK: {
		id: 'book',
		title: 'Book Online',
		step: 'INVENTORY',
		nextStepButtonText: 'Book',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'CONGRATS',
		type: 'submit',
	},
	PLAN: {
		id: 'plan',
		title: 'Review Your Move Plan',
		step: 'PLAN',
		nextStepButtonText: '',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: '',
		type: '',
	},
};

export const PROGRESS_BAR_STEPS = {
	MOBILE: [
		{ title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	DESKTOP: [
		{ title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	HOURLY_MOBILE: [
		{ title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	HOURLY_DESKTOP: [
		{ title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
};

export const PROGRESS_BAR_STEPS_BIDS = {
	MOBILE: [
		{ title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Thank You', state: 'THANKYOU', id: 'thankyou' },

		// { title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		// { title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	DESKTOP: [
		{ title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Thank You', state: 'THANKYOU', id: 'thankyou' },
		// { title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		// { title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	HOURLY_MOBILE: [
		{ title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Thank You', state: 'THANKYOU', id: 'thankyou' },

		// { title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	HOURLY_DESKTOP: [
		{ title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Thank You', state: 'THANKYOU', id: 'thankyou' },
		// { title: 'Book Online', state: 'BOOK', id: 'book' },
	],
};

export const PROGRESS_BAR_STEPS_MODAL = {
	MOBILE: [
		{ title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	DESKTOP: [
		{ title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	HOURLY_MOBILE: [
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
	HOURLY_DESKTOP: [
		{ title: 'Details', state: 'DETAILS', id: 'details' },
		{ title: 'Book Online', state: 'BOOK', id: 'book' },
	],
};

export const unpaktSupportNumber = '855-286-7258';

export const TWITTER_SHARE_TEXT =
	'Found the best way to move! @Unpakt - compare movers & prices! Use this link and save $20 on your move.';

// NOTE: THERE IS AN EMPTY ROOM AT POSITION 9
export const INVENTORY_ROOMS = [
	'Living Room',
	'Bedroom',
	'Dining Room',
	'Kitchen',
	'Kids Room',
	'Office',
	'Outdoor',
	'Other',
	'Boxes I Packed',
];

export const INVENTORY_GROUPS = [
	[
		{
			id: 27,
			name: 'Sofas and Couches',
			room_id: 1,
			icon_css_class: 'inventory_icons-Seater_Sofa_3',
		},
		{
			id: 28,
			name: 'Chairs',
			room_id: 1,
			icon_css_class: 'inventory_icons-Arm_Chair',
		},
		{
			id: 29,
			name: 'Tables',
			room_id: 1,
			icon_css_class: 'inventory_icons-Large_Coffee_Table',
		},
		{
			id: 63,
			name: 'Glass Tables',
			room_id: 1,
			icon_css_class: 'inventory_icons-Large_Coffee_Table',
		},
		{
			id: 30,
			name: 'TVs',
			room_id: 1,
			icon_css_class: 'inventory_icons-TV_FLAT_PANEL_46-55',
		},
		{
			id: 37,
			name: 'Stereo',
			room_id: 1,
			icon_css_class: 'inventory_icons-Stereo_or_Video_Component',
		},
		{
			id: 31,
			name: 'Cabinets',
			room_id: 1,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 32,
			name: 'Lamps',
			room_id: 1,
			icon_css_class: 'inventory_icons-Table_Lamp',
		},
		{
			id: 33,
			name: 'Bookcases',
			room_id: 1,
			icon_css_class: 'inventory_icons-Large_Bookcase',
		},
		{
			id: 34,
			name: 'Pianos',
			room_id: 1,
			icon_css_class: 'inventory_icons-Baby_Grand_Piano',
		},
		{
			id: 35,
			name: 'Mirrors',
			room_id: 1,
			icon_css_class: 'inventory_icons-Small_Mirror',
		},
		{
			id: 36,
			name: 'Paintings & Pictures',
			room_id: 1,
			icon_css_class: 'inventory_icons-Picture_with_Frame',
		},
		{
			id: 38,
			name: 'Odds & Ends',
			room_id: 1,
			icon_css_class: 'inventory_icons-Fireplace_Equipment',
		},
	],
	[
		{
			id: 1,
			name: 'Beds',
			room_id: 2,
			icon_css_class: 'inventory_icons-Bed',
		},
		{
			id: 2,
			name: 'Mattress Only',
			room_id: 2,
			icon_css_class: 'inventory_icons-California_King_Mattress',
		},
		{
			id: 3,
			name: 'Cabinets',
			room_id: 2,
			icon_css_class: 'inventory_icons-Small_Armoire',
		},
		{
			id: 4,
			name: 'TVs',
			room_id: 2,
			icon_css_class: 'inventory_icons-TV_FLAT_PANEL_46-55',
		},
		{
			id: 10,
			name: 'Stereo',
			room_id: 2,
			icon_css_class: 'inventory_icons-Stereo_or_Video_Component',
		},
		{
			id: 5,
			name: 'Lamps',
			room_id: 2,
			icon_css_class: 'inventory_icons-Table_Lamp',
		},
		{
			id: 6,
			name: 'Tables',
			room_id: 2,
			icon_css_class: 'inventory_icons-Night_Table',
		},
		{
			id: 7,
			name: 'Chairs',
			room_id: 2,
			icon_css_class: 'inventory_icons-Arm_Chair',
		},
		{
			id: 8,
			name: 'Mirrors',
			room_id: 2,
			icon_css_class: 'inventory_icons-Small_Mirror',
		},
		{
			id: 9,
			name: 'Bookcases',
			room_id: 2,
			icon_css_class: 'inventory_icons-Large_Bookcase',
		},
		{
			id: 11,
			name: 'Paintings & Pictures',
			room_id: 2,
			icon_css_class: 'inventory_icons-Picture_with_Frame',
		},
		{
			id: 12,
			name: 'Odds & Ends',
			room_id: 2,
			icon_css_class: 'inventory_icons-Clothes_Steamer',
		},
	],
	[
		{
			id: 13,
			name: 'Tables',
			room_id: 3,
			icon_css_class: 'inventory_icons-Dining_Table',
		},
		{
			id: 62,
			name: 'Glass Tables',
			room_id: 3,
			icon_css_class: 'inventory_icons-Dining_Table',
		},
		{
			id: 14,
			name: 'Chairs',
			room_id: 3,
			icon_css_class: 'inventory_icons-Dining_Chair',
		},
		{
			id: 15,
			name: 'Cabinets',
			room_id: 3,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 65,
			name: 'Paintings & Pictures',
			room_id: 3,
			icon_css_class: 'inventory_icons-Picture_with_Frame',
		},
		{
			id: 16,
			name: 'Odds & Ends',
			room_id: 3,
			icon_css_class: 'inventory_icons-Chandelier',
		},
	],
	[
		{
			id: 22,
			name: 'Tables',
			room_id: 4,
			icon_css_class: 'inventory_icons-Kitchen_Table',
		},
		{
			id: 23,
			name: 'Chairs',
			room_id: 4,
			icon_css_class: 'inventory_icons-Kitchen_Chair',
		},
		{
			id: 24,
			name: 'Cabinets',
			room_id: 4,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 25,
			name: 'Appliances',
			room_id: 4,
			icon_css_class: 'inventory_icons-Coffee_maker',
		},
		{
			id: 26,
			name: 'Odds & Ends',
			room_id: 4,
			icon_css_class: 'inventory_icons-Water_Cooler',
		},
	],
	[
		{
			id: 17,
			name: 'Beds',
			room_id: 5,
			icon_css_class: 'inventory_icons-Childrens_Bed',
		},
		{
			id: 18,
			name: 'Tables',
			room_id: 5,
			icon_css_class: 'inventory_icons-Childrens_Table',
		},
		{
			id: 19,
			name: 'Chairs',
			room_id: 5,
			icon_css_class: 'inventory_icons-Childrens_Chair',
		},
		{
			id: 20,
			name: 'Toys',
			room_id: 5,
			icon_css_class: 'inventory_icons-Toy_Car',
		},
		{
			id: 21,
			name: 'Odds & Ends',
			room_id: 5,
			icon_css_class: 'inventory_icons-Stroller',
		},
	],
	[
		{
			id: 39,
			name: 'Tables & Desks',
			room_id: 6,
			icon_css_class: 'inventory_icons-Small_Desk',
		},
		{
			id: 40,
			name: 'Chairs',
			room_id: 6,
			icon_css_class: 'inventory_icons-Office-Chair',
		},
		{
			id: 41,
			name: 'Computers',
			room_id: 6,
			icon_css_class: 'inventory_icons-Computer',
		},
		{
			id: 42,
			name: 'Monitors',
			room_id: 6,
			icon_css_class: 'inventory_icons-Monitor',
		},
		{
			id: 43,
			name: 'Equipment',
			room_id: 6,
			icon_css_class: 'inventory_icons-Fax_Machine',
		},
		{
			id: 44,
			name: 'Cabinets',
			room_id: 6,
			icon_css_class: 'inventory_icons-Drawer_Lateral_4',
		},
		{
			id: 45,
			name: 'Bookcase',
			room_id: 6,
			icon_css_class: 'inventory_icons-Large_Bookcase',
		},
	],
	[
		{
			id: 57,
			name: 'Tables',
			room_id: 7,
			icon_css_class: 'inventory_icons-Outdoor_Dining_Table',
		},
		{
			id: 58,
			name: 'Chairs',
			room_id: 7,
			icon_css_class: 'inventory_icons-Outdoor_Chair',
		},
		{
			id: 59,
			name: 'Sofas',
			room_id: 7,
			icon_css_class: 'inventory_icons-Outdoor_Sofa',
		},
		{
			id: 60,
			name: 'Plants',
			room_id: 7,
			icon_css_class: 'inventory_icons-Medium_Plant',
		},
		{
			id: 61,
			name: 'Grills',
			room_id: 7,
			icon_css_class: 'inventory_icons-Large_Grill',
		},
	],
	[
		{
			id: 46,
			name: 'Instruments',
			room_id: 8,
			icon_css_class: 'inventory_icons-Guitar',
		},
		{
			id: 47,
			name: 'Chairs',
			room_id: 8,
			icon_css_class: 'inventory_icons-Dining_Chair',
		},
		{
			id: 64,
			name: 'Cabinets',
			room_id: 8,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 48,
			name: 'Sports & Hobbies',
			room_id: 8,
			icon_css_class: 'inventory_icons-Snowboard',
		},
		{
			id: 49,
			name: 'Home Appliances',
			room_id: 8,
			icon_css_class: 'inventory_icons-Vacuum_Cleaner',
		},
		{
			id: 50,
			name: 'Stereo Equipment',
			room_id: 8,
			icon_css_class: 'inventory_icons-Stereo_or_Video_Component',
		},
		{
			id: 51,
			name: 'Games',
			room_id: 8,
			icon_css_class: 'inventory_icons-Pool_Table',
		},
		{
			id: 52,
			name: 'Odds & Ends',
			room_id: 8,
			icon_css_class: 'inventory_icons-Basket',
		},
		{
			id: 53,
			name: 'Tables',
			room_id: 8,
			icon_css_class: 'inventory_icons-Dining_Table',
		},
		{
			id: 54,
			name: 'Rugs',
			room_id: 8,
			icon_css_class: 'inventory_icons-Large_Rug',
		},
		{
			id: 55,
			name: 'Art & Sculptures',
			room_id: 8,
			icon_css_class: 'inventory_icons-Sculpture',
		},
		{
			id: 56,
			name: 'Travel & Storage',
			room_id: 8,
			icon_css_class: 'inventory_icons-Trunk',
		},
	],
	[
		{
			id: 66,
			name: 'Boxes',
			room_id: 10,
			icon_css_class: 'inventory_icons-Box',
		},
	],
];
export const HOME_SIZES = {
	1: {
		type: 'Studio',
		size: '400-600 sq ft',
		short_formatted_type: 'A studio',
	},
	2: {
		type: 'Studio Alcove',
		size: '600-700 sq ft',
		short_formatted_type: 'A studio alcove',
	},
	3: {
		type: '1 Bedroom, Small',
		size: '600-800 sq ft',
		short_formatted_type: '1 small bedroom',
	},
	4: {
		type: '1 Bedroom, Large',
		size: '800-1000 sq ft',
		short_formatted_type: '1 large bedroom',
	},
	5: {
		type: '2 Bedroom',
		size: '1000 to 1500 sq ft',
		short_formatted_type: '2 bedrooms',
	},
	6: {
		type: '3 Bedroom',
		size: '1500 to 2000 sq ft',
		short_formatted_type: '3 bedrooms',
	},
	7: {
		type: '4+ Bedroom',
		size: 'over 2000 sq ft',
	},
	8: {
		type: 'Select Home Size',
		size: '',
		short_formatted_type: 'Select Home Size',
	},
	16: {
		type: 'Just a few items',
		size: '',
		short_formatted_type: 'Just a few items',
	},
	17: {
		type: 'One item',
		size: '',
		short_formatted_type: 'One item',
	},
};

export const INVENTORY_BOXES = [
	{
		id: 1,
		name: 'Small Box',
		description: 'For small and heavy items',
		size: '12"x12.5"x17"',
		cubic_feet: 1.5,
		icon: 'linen',
	},
	{
		id: 2,
		name: 'Medium Box',
		description: 'For general stuff',
		size: '16"x16"x18"',
		cubic_feet: 3.0,
		icon: 'medium',
	},
	{
		id: 3,
		name: 'Large Box',
		description: 'For large and light items',
		size: '20"x20"x18"',
		cubic_feet: 4.5,
		icon: 'large',
	},
	{
		id: 4,
		name: 'China/Dish Box',
		description: 'For fragile items',
		size: '18"x18"x28"',
		cubic_feet: 5.2,
		icon: 'china',
	},
	{
		id: 5,
		name: 'File Box',
		description: 'For folders and files',
		size: '12"x12"x24"',
		cubic_feet: 2.0,
		icon: 'letter',
	},
	{
		id: 6,
		name: 'Legal File Box',
		description: 'For folders',
		size: '12"x15"x24"',
		cubic_feet: 2.5,
		icon: 'legal',
	},
	{
		id: 7,
		name: 'Wardrobe Box',
		description: 'For hangers',
		size: '24"x23"x48"',
		cubic_feet: 15.5,
		icon: 'wardrobe',
	},
];

export const FLOW_STEPS_MAP = {
	inventory: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
			onLoad: 'startSaveTimeOut',
		},
	},
	details: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
			onLoad: 'startSaveTimeOut',
		},
		location: {
			text: 'continue',
			action: 'submitLocation',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
			onLoad: 'startSaveTimeOut',
		},
	},
	compare: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: true,
			listener: false,
			redirectTo: undefined,
			onLoad: 'startSaveTimeOut',
		},
		location: {
			text: 'continue',
			action: 'submitLocation',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
			onLoad: 'startSaveTimeOut',
		},
		compare: {
			text: '',
			action: 'checkPlanState',
			show: false,
			loader: false,
			listener: false,
			redirectTo: undefined,
			onLoad: 'startSaveTimeOut',
		},
	},
	confirm: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
		location: {
			text: 'save',
			action: 'submitLocation',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
		compare: {
			text: '',
			action: 'checkPlanState',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
	},
	book: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
		location: {
			text: 'save',
			action: 'submitLocation',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
		compare: {
			text: '',
			action: 'checkPlanState',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
	},
	congrats: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: false,
			listener: false,
			redirectTo: 'plan',
		},
		location: {
			text: 'save',
			action: 'submitLocation',
			show: true,
			loader: false,
			listener: false,
			redirectTo: 'plan',
		},
		compare: {
			text: '',
			action: 'checkPlanState',
			show: true,
			loader: false,
			listener: false,
			redirectTo: 'plan',
		},
	},
	billing: {
		inventory: {
			text: 'continue',
			action: 'submitInventory',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
		location: {
			text: 'save',
			action: 'submitLocation',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
		compare: {
			text: '',
			action: 'checkPlanState',
			show: true,
			loader: false,
			listener: false,
			redirectTo: undefined,
		},
	},
};

export const TIME_OPTIONS = [
	'8:00 AM - 9:00 AM',
	'9:00 AM - 10:00 AM',
	'10:00 AM - 11:00 AM',
	'11:00 AM - 12:00 PM',
	'12:00 PM - 1:00 PM',
	'1:00 PM - 2:00 PM',
	'2:00 PM - 3:00 PM',
	'3:00 PM - 4:00 PM',
	'4:00 PM - 5:00 PM',
	'5:00 PM - 6:00 PM',
	'6:00 PM - 7:00 PM',
];

export const TIME_OPTIONS_SELECT = [
	{ value: '8:00 AM - 9:00 AM', label: '8:00 AM - 9:00 AM', disabled: false },
	{ value: '9:00 AM - 10:00 AM', label: '9:00 AM - 10:00 AM', disabled: false },
	{
		value: '10:00 AM - 11:00 AM',
		label: '10:00 AM - 11:00 AM',
		disabled: false,
	},
	{
		value: '11:00 AM - 12:00 PM',
		label: '11:00 AM - 12:00 PM',
		disabled: false,
	},
	{ value: '12:00 PM - 1:00 PM', label: '12:00 PM - 1:00 PM', disabled: false },
	{ value: '1:00 PM - 2:00 PM', label: '1:00 PM - 2:00 PM', disabled: false },
	{ value: '2:00 PM - 3:00 PM', label: '2:00 PM - 3:00 PM', disabled: false },
	{ value: '3:00 PM - 4:00 PM', label: '3:00 PM - 4:00 PM', disabled: false },
	{ value: '4:00 PM - 5:00 PM', label: '4:00 PM - 5:00 PM', disabled: false },
	{ value: '5:00 PM - 6:00 PM', label: '5:00 PM - 6:00 PM', disabled: false },
	{ value: '6:00 PM - 7:00 PM', label: '6:00 PM - 7:00 PM', disabled: false },
];

export const ELEVATOR_OPTIONS_SELECT = [
	{ value: '7:00 AM', label: '7:00 AM', disabled: false },
	{ value: '8:00 AM', label: '8:00 AM', disabled: false },
	{ value: '9:00 AM', label: '9:00 AM', disabled: false },
	{ value: '10:00 AM', label: '10:00 AM', disabled: false },
	{ value: '11:00 AM', label: '11:00 AM', disabled: false },
	{ value: '12:00 PM', label: '12:00 PM', disabled: false },
	{ value: '1:00 PM', label: '1:00 PM', disabled: false },
	{ value: '2:00 PM', label: '2:00 PM', disabled: false },
	{ value: '3:00 PM', label: '3:00 PM', disabled: false },
	{ value: '4:00 PM', label: '4:00 PM', disabled: false },
	{ value: '5:00 PM', label: '5:00 PM', disabled: false },
	{ value: '6:00 PM', label: '6:00 PM', disabled: false },
	{ value: '7:00 PM', label: '7:00 PM', disabled: false },
	{ value: '8:00 PM', label: '8:00 PM', disabled: false },
	{ value: '9:00 PM', label: '9:00 PM', disabled: false },
	{ value: '10:00 PM', label: '10:00 PM', disabled: false },
];

export const TIME_FRAMES = [
	{
		time: '8-10 AM',
		full_time: '8:00 AM - 10:00 AM',
	},
	{
		time: '9-11 AM',
		full_time: '9:00 AM - 11:00 AM',
	},
	{
		time: '1-3 PM',
		full_time: '1:00 PM - 3:00 PM',
	},
	{
		time: '2-4 PM',
		full_time: '2:00 PM - 4:00 PM',
	},
];

export const NUMBER_OF_LABORERERS = [
	{
		text_num: '1',
		value: 1,
	},
	{
		text_num: '2',
		value: 2,
	},
	{
		text_num: '3',
		value: 3,
	},
	{
		text_num: '4',
		value: 4,
	},
	{
		text_num: '5',
		value: 5,
	},
	{
		text_num: '6',
		value: 6,
	},
];

export const TRUCK_SIZE_SELECT = [
	{
		value: 1,
		text: 'No Truck',
		subText: 'labor only',
		short_formatted_type: 'I just need labor',
	},
	{
		value: 2,
		text: 'Small Truck',
		subText: '(10-13 ft)',
		short_formatted_type: 'A studio',
	},
	{
		value: 3,
		text: 'Medium Truck',
		subText: '(14-19 ft)',
		short_formatted_type: '1 small bedroom',
	},
	{
		value: 4,
		text: 'Large Truck',
		subText: '(20-26 ft)',
		short_formatted_type: '2 bedrooms',
	},
	{
		value: 5,
		text: 'Extra Large Truck',
		subText: '(>27 ft)',
		short_formatted_type: '3+ bedrooms',
	},
];
export const HOME_SIZES_SELECT = [
	{
		value: 17,
		text: 'One item',
		subText: false,
		short_formatted_type: 'One item',
	},
	{
		value: 16,
		text: 'Just a few items',
		subText: false,
		short_formatted_type: 'Just a few items',
	},
	{
		value: 1,
		text: 'Studio',
		subText: '(400-600 sq ft)',
		short_formatted_type: 'A studio',
	},
	{
		value: 2,
		text: 'Studio Alcove',
		subText: '(600-700 sq ft)',
		short_formatted_type: 'A studio alcove',
	},
	{
		value: 3,
		text: '1 Bedroom, Small',
		subText: '(600-800 sq ft)',
		short_formatted_type: '1 small bedroom',
	},
	{
		value: 4,
		text: '1 Bedroom, Large',
		subText: '(800-1000 sq ft)',
		short_formatted_type: '1 large bedroom',
	},
	{
		value: 5,
		text: '2 Bedroom',
		subText: '(1000 to 1500 sq ft)',
		short_formatted_type: '2 bedrooms',
	},
	{
		value: 6,
		text: '3 Bedroom',
		subText: '(1500 to 2000 sq ft)',
		short_formatted_type: '3 bedrooms',
	},
	{
		value: 7,
		text: '4+ Bedroom',
		subText: 'over 2000 sq ft',
		short_formatted_type: '4+ bedrooms',
	},
	{
		value: 8,
		text: 'Select Home Size',
		subText: false,
		short_formatted_type: 'Select Home Size',
	},
];

export const STATES = [
	{ value: 'AL', label: 'AL', full: 'Alabama' },
	{ value: 'AK', label: 'AK', full: 'Alaska' },
	{ value: 'AZ', label: 'AZ', full: 'Arizona' },
	{ value: 'AR', label: 'AR', full: 'Arkansas' },
	{ value: 'CA', label: 'CA', full: 'California' },
	{ value: 'CO', label: 'CO', full: 'Colorado' },
	{ value: 'CT', label: 'CT', full: 'Connecticut' },
	{ value: 'DC', label: 'DC', full: 'District of Columbia' },
	{ value: 'DE', label: 'DE', full: 'Delaware' },
	{ value: 'FL', label: 'FL', full: 'Florida' },
	{ value: 'GA', label: 'GA', full: 'Georgia' },
	{ value: 'HI', label: 'HI', full: 'Hawaii' },
	{ value: 'ID', label: 'ID', full: 'Idaho' },
	{ value: 'IL', label: 'IL', full: 'Illinois' },
	{ value: 'IN', label: 'IN', full: 'Indiana' },
	{ value: 'IA', label: 'IA', full: 'Iowa' },
	{ value: 'KS', label: 'KS', full: 'Kansas' },
	{ value: 'KY', label: 'KY', full: 'Kentucky' },
	{ value: 'LA', label: 'LA', full: 'Louisiana' },
	{ value: 'ME', label: 'ME', full: 'Maine' },
	{ value: 'MD', label: 'MD', full: 'Maryland' },
	{ value: 'MA', label: 'MA', full: 'Massachusetts' },
	{ value: 'MI', label: 'MI', full: 'Michigan' },
	{ value: 'MN', label: 'MN', full: 'Minnesota' },
	{ value: 'MS', label: 'MS', full: 'Mississippi' },
	{ value: 'MO', label: 'MO', full: 'Missouri' },
	{ value: 'MT', label: 'MT', full: 'Montana' },
	{ value: 'NE', label: 'NE', full: 'Nebraska' },
	{ value: 'NV', label: 'NV', full: 'Nevada' },
	{ value: 'NH', label: 'NH', full: 'New Hampshire' },
	{ value: 'NJ', label: 'NJ', full: 'New Jersey' },
	{ value: 'NM', label: 'NM', full: 'New Mexico' },
	{ value: 'NY', label: 'NY', full: 'New York' },
	{ value: 'NC', label: 'NC', full: 'North Carolina' },
	{ value: 'ND', label: 'ND', full: 'North Dakota' },
	{ value: 'OH', label: 'OH', full: 'Ohio' },
	{ value: 'OK', label: 'OK', full: 'Oklahoma' },
	{ value: 'OR', label: 'OR', full: 'Oregon' },
	{ value: 'PA', label: 'PA', full: 'Pennsylvania' },
	{ value: 'RI', label: 'RI', full: 'Rhode Island' },
	{ value: 'SC', label: 'SC', full: 'South Carolina' },
	{ value: 'SD', label: 'SD', full: 'South Dakota' },
	{ value: 'TN', label: 'TN', full: 'Tennessee' },
	{ value: 'TX', label: 'TX', full: 'Texas' },
	{ value: 'UT', label: 'UT', full: 'Utah' },
	{ value: 'VT', label: 'VT', full: 'Vermont' },
	{ value: 'VA', label: 'VA', full: 'Virginia' },
	{ value: 'WA', label: 'WA', full: 'Washington' },
	{ value: 'WV', label: 'WV', full: 'West Virginia' },
	{ value: 'WI', label: 'WI', full: 'Wisconsin' },
	{ value: 'WY', label: 'WY', full: 'Wyoming' },
];

export const HEIGHTS_BY_ID = {
	1: 'Elevator',
	2: 'No stairs - ground floor',
	3: 'Stairs - 2nd floor',
	4: 'Stairs - 3rd floor',
	5: 'Stairs - 4th floor',
	6: 'Stairs - 5th floor',
	7: 'Stairs - 6th floor',
};

export const HEIGHTS = [
	{ value: '1', label: 'Elevator' },
	{ value: '2', label: 'No stairs - ground floor' },
	{ value: '3', label: 'Stairs - 2nd floor' },
	{ value: '4', label: 'Stairs - 3rd floor' },
	{ value: '5', label: 'Stairs - 4th floor' },
	{ value: '6', label: 'Stairs - 5th floor' },
	{ value: '7', label: 'Stairs - 6th floor' },
];

export const CHECKLIST_ITEMS = [
	{
		title: 'Reserve your elevator and ask if a COI is required',
		content: () => (
			<div>
				<h3>Reserve Your Elevator</h3>
				<p>
					Double check with your building management and verify what dates and
					times you can move. Reserve your elevator with your building
					management and update your Move Plan accordingly.
				</p>
				<h3>Ask if a COI is required</h3>
				<p>
					Does your building have shared spaces? You may need a Certificate of
					Insurance (COI). Reach out to your building management at both pick up
					and drop off locations with plenty of time ahead of your move date to
					see if a COI is required. If COI is required, you won’t be able to
					move without one!
				</p>
				<p>
					If a COI is required, add it to your Move Plan and include your
					building management\’s contact info. If your building management sent
					you a COI Sample or instructions, email this to your mover. Allow at
					least 2 business days for the movers to issue and send the requested
					COI.
				</p>
			</div>
		),
		tip: 'Reserving the elevator and notifying the moving company that a COI is required is crucial in order to make sure movers can complete your move.',
		image: '/coi_required.png',
	},

	{
		title: 'Request boxes, packing, or additional services',
		content: () => (
			<div>
				<p>
					If you wish to add additional services, simply go to your Move Plan
					and update it.
				</p>
				<h3>order boxes</h3>
				<p>
					If you want to pack yourself and would like boxes to be delivered to
					you before your move add box delivery to your Move Plan.
				</p>
				<h3>Packing and Unpacking</h3>
				<p>
					If you want the movers to come with boxes, pack all your stuff and
					even unpack it at your new places add Professional Packing or
					Professional Packing and Unpacking to your Move Plan.
				</p>
				<h3>Storage</h3>
				<p>
					If your new place is not ready to move in yet, you can store your
					stuff for a while. The mover will deliver your stuff when your new
					place is ready. Storage duration of up to 45 days can be added to your
					Move Plan.
				</p>
				<h3>Furniture special handling</h3>
				<p>
					Typical moving tasks like unscrewing legs from a sofa are included in
					the price of your move. Special handling, however, may be required for
					more complex needs. If you have an item that wasn’t moved in to your
					place by a mover, verify it doesn’t require a specialist or add
					special handling to your Move Plan.
				</p>
			</div>
		),
		tip: 'It is recommended to add special services to your Move Plan before your move day so mover can come prepared with the right tools and equipment. Prices vary between moving companies.',
		image: '/request_boxes.png',
	},
	{
		title: 'Request additional coverage or insurance',
		content: () => (
			<div>
				<p>
					If you have fragile and/or high value items, purchasing additional
					insurance is recommended.
				</p>
				<p>
					Under Federal law, interstate movers must offer two different
					liability options referred to as valuation coverage:
				</p>
				<h3>
					OPTION A - RELEASED VALUE (DEFAULT COVERAGE WITH NO ADDITIONAL
					PAYMENT)
				</h3>
				<p>
					The protection is minimal. Under this option, the mover assumes
					liability for no more than 60 cents per pound per article. For
					example, if your mover lost or damaged a 10-pound stereo component
					valued at $1,000, you would only receive $6.00 in compensation (60
					cents x 10 pounds).
				</p>
				<h3>OPTION B - FULL VALUE PROTECTION (ADDITIONAL PAYMENT)</h3>
				<p>
					Under Full Value Protection, your mover is liable for the replacement
					value of lost or damaged goods in your entire shipment. If any article
					is lost, destroyed or damaged while in your mover's custody, your
					mover will, at its discretion, offer to do one of the following for
					each item:
				</p>
				<ul>
					<li>Repair the item</li>
					<li>Replace with a similar item</li>
					<li>
						Make a cash settlement for the cost of the repair or the current
						market replacement value.
					</li>
				</ul>
				<p>
					Under this option, movers are permitted to limit their liability for
					loss or damage to articles of extraordinary value, unless you
					specifically list these articles on the shipping documents. The exact
					cost for Full Value Protection varies by mover and may be subject to
					various deductible levels of liability that may reduce your cost. Ask
					your mover for written details of their Full Value Protection plan.
				</p>
				<p>
					<strong>
						Movers are only liable for boxes they packed themselves, not for
						boxes packed by the owner.
					</strong>
				</p>
				<h3>THIRD-PARTY INSURANCE</h3>
				<p>
					The cost of this insurance is not included in the basic move and must
					be purchased separately by you. This is not valuation coverage
					governed by Federal law—it is optional insurance regulated by State
					law. You have the option of purchasing insurance from a third-party
					insurance company. Before purchasing insurance, check your homeowner's
					insurance policy to see if you're already covered.
				</p>
			</div>
		),
		tip: 'We recommend purchasing additional coverage from your moving company or third party insurance company just in case the unexpected happens.',
		image: '/coverage.png',
	},
	{
		title: 'Review possible required services on your move day',
		content: () => (
			<div>
				<h3>POTENTIAL SERVICES REQUIRED ON YOUR MOVE DAY</h3>
				<p>
					Sometimes (not for every move!), additional services are required and
					charges may apply. Here’s when:
				</p>
				<ul>
					<li>
						<strong>Changes to the Inventory</strong> - You requested to move
						additional inventory that wasn’t entered into your booked Move Plan
						or the items that need to be moved are different than what is
						entered into your booked Move Plan
					</li>
					<li>
						<strong>Additional Services</strong> - You requested additional
						services when the movers arrived (example: ask movers to help you
						pack boxes)
					</li>
					<li>
						<strong>Conditions at your Location</strong> - The conditions of the
						job have changed (example: elevator is out of service and now movers
						must carry your furniture up and down 4 flights of stairs)
					</li>
					<li>
						<strong>Long Carry</strong> - If the distance from the truck to the
						building entrance is more than 200 feet, there might be a Long Carry
						fee (example: movers can’t park near your current home or new home
						and need to carry (or dolly) items for a long distance.)
					</li>
					<li>
						<strong>Wait Time</strong> - If the movers have to wait to get
						access to your current or new home, there will be a Wait Time fee
						(example: movers can't start the move right away because you are not
						ready or they can't access the building or your elevator reservation
						starts at 10:00 am but you requested for the movers to arrive at
						8:00 am)
					</li>
					<li>
						<strong>Shuttle</strong> - If you’re moving between states and an
						interstate moving truck (18-wheeler) can’t fit into your new home’s
						neighborhood and a smaller moving truck is required, there will be a
						Shuttle Fee.
					</li>
				</ul>
			</div>
		),
		tip: "These fees do not apply for every move! If you'd like additional information about any of the above fees, please don’t hesitate to contact Unpakt.",
		image: '/required_services.png',
	},
	{
		title: 'Update your Move Plan anytime',
		content: () => (
			<div>
				<p>
					You can update your inventory up until the day of the move.{' '}
					<strong>
						We recommend finalizing it two business days before your move day
						for accurate{' '}
					</strong>
					, but we understand that sometimes there's a box or two hidden away.
				</p>
				<p>
					You can download our{' '}
					<a href="https://itunes.apple.com/us/app/unpakt/id632832637?mt=8">
						iPhone app
					</a>{' '}
					to update your inventory during your move day when your computer is
					packed away.
				</p>
			</div>
		),
		tip: 'Your price is guaranteed based on your inventory and details of your move! If you add more items, your price will increase by the same rates used on your original booking. If you end up moving less items, we will issue a refund accordingly (See Move Day! for details).',
		image: '/update_move_plan.png',
	},
	{
		title: 'Payment',
		active: false,
		content: () => (
			<div>
				<h3>
					PAYMENT IS PROCESSED BY UNPAKT. MOVER GETS PAID ONLY AFTER YOUR MOVE
					IS COMPLETED
				</h3>
				<p>
					If changes are made after your payment has already been processed,
					we'll bill you separately after your move date. (See Move Day! for
					details)
				</p>
				<ul>
					<li>
						<strong>
							Payment is processed by Unpakt two business days before your move
						</strong>
					</li>
					<li>
						<strong>
							Free cancellation up to two business days before your move
						</strong>
					</li>
					<li>
						<strong>
							10% cancellation fee applies if you cancel within two business
							days before your move
						</strong>
					</li>
				</ul>
				<h3>DO NOT EXCHANGE MONEY DIRECTLY WITH THE MOVER!</h3>
				<p>
					You should not exchange money with your mover (except for a tip,
					should you choose to give one). Billing occurs through Unpakt. Contact
					us as soon as you can if the movers ask for any payments during your
					move.
				</p>
			</div>
		),
		tip: 'You will only be billed for the items you end up moving and for the services you actually receive!',
		image: '/payment.png',
	},
	{
		title: 'Move Day!',
		content: () => (
			<div>
				<h3>WHAT TO EXPECT?</h3>
				<p>
					You should expect your movers to show up within the estimated arrival
					time frame, treat your belongings with care, and handle your move in a
					courteous, respectful manner. Remember, our movers are held to a high
					standard of quality, so please contact us as soon as possible if you
					have any issue and note your experience in the Customer Feedback
					Survey, which will be sent to you (2 days) after your move day.
				</p>
				<h3>ANY DOCUMENTS TO SIGN?</h3>
				<p>
					You will be asked by your movers to sign a “Bill of Lading”, possibly
					an “Order for Service”, and an “Inventory list”. This paperwork gives
					the movers legal permission to move your belongings. As with anything,
					make sure you read everything carefully before signing, and contact us
					with any questions or concerns. Don’t forget to ask for a copy!
				</p>
				<h3>WHAT IF I HAVE MORE/LESS STUFF TO MOVE?</h3>
				<p>
					If you end up moving more/less items than noted in your original Move
					Plan, it should be listed and initialed on the “Bill of Lading” movers
					will present to you on your pickup location.
				</p>
				<h3>READ BEFORE YOU SIGN!</h3>
				<p>
					You should never sign that delivery has been made while you are still
					at the pickup location. If there are any changes in inventory or
					services, please initial to give your approval and ask if there are
					additional fees for those services, which is common.
				</p>
			</div>
		),
		tip: 'Make sure any changes from your original Move Plan are noted and signed for on you Bill Of Lading.',
		image: '/move_day.png',
	},
	{
		title: 'Take the Unpakt Feedback Survey, enter to win $100 egift card',
		active: false,
		content: () => (
			<div>
				<h3>HOW WAS YOUR MOVE?</h3>
				<p>
					We will email you a Feedback Survey 2 days after your move date. Keep
					an eye out in your inbox and don't forget to share your feedback!
					After submitting your feedback, you will automatically be entered into
					our monthly drawing for a chance to win a $100 Target eGiftcard.
				</p>
			</div>
		),
		tip: 'Let us know if your experience with Unpakt was good or bad. We will also appreciate if you leave a review about the mover you have used to help others make the best choice for their move.',
		image: '/gift_card.png',
	},
];

export const CITY_LAT_LONGS = [
	{
		city: 'Birmingham',
		state: 'AL',
		lat: 33.5185892,
		long: -86.8103567,
	},
	{
		city: 'Anchorage',
		state: 'AK',
		lat: 61.2180556,
		long: -149.9002778,
	},
	{
		city: 'Phoenix',
		state: 'AZ',
		lat: 33.4483771,
		long: -112.0740373,
	},
	{
		city: 'Scottsdale',
		state: 'AZ',
		lat: 33.4941704,
		long: -111.9260519,
	},
	{
		city: 'Tempe',
		state: 'AZ',
		lat: 33.4255104,
		long: -111.9400054,
	},
	{
		city: 'Tucson',
		state: 'AZ',
		lat: 32.2226066,
		long: -110.9747108,
	},
	{
		city: 'Little Rock',
		state: 'AR',
		lat: 34.7464809,
		long: -92.2895948,
	},
	{
		city: 'Fresno',
		state: 'CA',
		lat: 36.7377981,
		long: -119.7871247,
	},
	{
		city: 'Irvine',
		state: 'CA',
		lat: 33.6845673,
		long: -117.8265049,
	},
	{
		city: 'Los Angeles',
		state: 'CA',
		lat: 34.0522342,
		long: -118.2436849,
	},
	{
		city: 'Oakland',
		state: 'CA',
		lat: 37.8043514,
		long: -122.2711639,
	},
	{
		city: 'Sacramento',
		state: 'CA',
		lat: 38.5815719,
		long: -121.4943996,
	},
	{
		city: 'San Diego',
		state: 'CA',
		lat: 32.715738,
		long: -117.1610838,
	},
	{
		city: 'San Francisco',
		state: 'CA',
		lat: 37.7749295,
		long: -122.4194155,
	},
	{
		city: 'San Jose',
		state: 'CA',
		lat: 37.3382082,
		long: -121.8863286,
	},
	{
		city: 'Aurora',
		state: 'CO',
		lat: 39.7294319,
		long: -104.8319195,
	},
	{
		city: 'Colorado Springs',
		state: 'CO',
		lat: 38.8338816,
		long: -104.8213634,
	},
	{
		city: 'Denver',
		state: 'CO',
		lat: 39.7392358,
		long: -104.990251,
	},
	{
		city: 'Hartford',
		state: 'CT',
		lat: 41.7658043,
		long: -72.6733723,
	},
	{
		city: 'New Haven',
		state: 'CT',
		lat: 41.308274,
		long: -72.9278835,
	},
	{
		city: 'Stamford',
		state: 'CT',
		lat: 41.0534302,
		long: -73.5387341,
	},
	{
		city: 'Washington',
		state: 'DC',
		lat: 38.9071923,
		long: -77.0368707,
	},
	{
		city: 'Wilmington',
		state: 'DE',
		lat: 39.744655,
		long: -75.5483909,
	},
	{
		city: 'Fort Lauderdale',
		state: 'FL',
		lat: 26.1224386,
		long: -80.1373174,
	},
	{
		city: 'Fort Myers',
		state: 'FL',
		lat: 26.640628,
		long: -81.8723084,
	},
	{
		city: 'Jacksonville',
		state: 'FL',
		lat: 30.3321838,
		long: -81.655651,
	},
	{
		city: 'Miami',
		state: 'FL',
		lat: 25.7616798,
		long: -80.1917902,
	},
	{
		city: 'Orlando',
		state: 'FL',
		lat: 28.5383355,
		long: -81.3792365,
	},
	{
		city: 'Palm Beach',
		state: 'FL',
		lat: 26.7056206,
		long: -80.0364297,
	},
	{
		city: 'Pompano Beach',
		state: 'FL',
		lat: 26.2378597,
		long: -80.1247667,
	},
	{
		city: 'Tampa',
		state: 'FL',
		lat: 27.950575,
		long: -82.4571776,
	},
	{
		city: 'Atlanta',
		state: 'GA',
		lat: 33.7489954,
		long: -84.3879824,
	},
	{
		city: 'Honolulu',
		state: 'HI',
		lat: 21.3069444,
		long: -157.8583333,
	},
	{
		city: 'Idaho Falls',
		state: 'ID',
		lat: 43.4926607,
		long: -112.0407584,
	},
	{
		city: 'Chicago',
		state: 'IL',
		lat: 41.8781136,
		long: -87.6297982,
	},
	{
		city: 'Indianapolis',
		state: 'IN',
		lat: 39.768403,
		long: -86.158068,
	},
	{
		city: 'Cedar Rapids',
		state: 'IA',
		lat: 41.9778795,
		long: -91.6656232,
	},
	{
		city: 'Kansas City',
		state: 'KS',
		lat: 39.1155314,
		long: -94.6267873,
	},
	{
		city: 'Wichita',
		state: 'KS',
		lat: 37.6871761,
		long: -97.330053,
	},
	{
		city: 'Louisville',
		state: 'KY',
		lat: 38.2526647,
		long: -85.7584557,
	},
	{
		city: 'Baton Rouge',
		state: 'LA',
		lat: 30.4514677,
		long: -91.1871466,
	},
	{
		city: 'New Orleans',
		state: 'LA',
		lat: 29.9510658,
		long: -90.0715323,
	},
	{
		city: 'Portland',
		state: 'ME',
		lat: 43.6590993,
		long: -70.2568189,
	},
	{
		city: 'Baltimore',
		state: 'MD',
		lat: 39.2903848,
		long: -76.6121893,
	},
	{
		city: 'Boston',
		state: 'MA',
		lat: 42.3600825,
		long: -71.0588801,
	},
	{
		city: 'Cambridge',
		state: 'MA',
		lat: 42.3736158,
		long: -71.1097335,
	},
	{
		city: 'Detroit',
		state: 'MI',
		lat: 42.331427,
		long: -83.0457538,
	},
	{
		city: 'Minneapolis',
		state: 'MN',
		lat: 44.977753,
		long: -93.2650108,
	},
	{
		city: 'Kansas City',
		state: 'MO',
		lat: 39.0997265,
		long: -94.5785667,
	},
	{
		city: 'St Louis',
		state: 'MO',
		lat: 38.6270025,
		long: -90.1994042,
	},
	{
		city: 'Omaha',
		state: 'NE',
		lat: 41.2565369,
		long: -95.9345034,
	},
	{
		city: 'Las Vegas',
		state: 'NV',
		lat: 36.1699412,
		long: -115.1398296,
	},
	{
		city: 'Reno',
		state: 'NV',
		lat: 39.5296329,
		long: -119.8138027,
	},
	{
		city: 'Manchester',
		state: 'NH',
		lat: 42.9956397,
		long: -71.4547891,
	},
	{
		city: 'Hoboken',
		state: 'NJ',
		lat: 40.7439905,
		long: -74.0323626,
	},
	{
		city: 'Jersey City',
		state: 'NJ',
		lat: 40.7177545,
		long: -74.0431435,
	},
	{
		city: 'Newark',
		state: 'NJ',
		lat: 40.735657,
		long: -74.1723667,
	},
	{
		city: 'Albuquerque',
		state: 'NM',
		lat: 35.0843859,
		long: -106.650422,
	},
	{
		city: 'New York',
		state: 'NY',
		lat: 40.755931,
		long: -73.984606,
	},
	{
		city: 'Charlotte',
		state: 'NC',
		lat: 35.2270869,
		long: -80.8431267,
	},
	{
		city: 'Raleigh',
		state: 'NC',
		lat: 35.7795897,
		long: -78.6381787,
	},
	{
		city: 'Fargo',
		state: 'ND',
		lat: 46.8771863,
		long: -96.7898034,
	},
	{
		city: 'Cincinnati',
		state: 'OH',
		lat: 39.1031182,
		long: -84.5120196,
	},
	{
		city: 'Cleveland',
		state: 'OH',
		lat: 41.49932,
		long: -81.6943605,
	},
	{
		city: 'Columbus',
		state: 'OH',
		lat: 39.9611755,
		long: -82.9987942,
	},
	{
		city: 'Tulsa',
		state: 'OK',
		lat: 36.1539816,
		long: -95.992775,
	},
	{
		city: 'Portland',
		state: 'OR',
		lat: 45.5154586,
		long: -122.6793461,
	},
	{
		city: 'Philadelphia',
		state: 'PA',
		lat: 39.9525839,
		long: -75.1652215,
	},
	{
		city: 'Pittsburgh',
		state: 'PA',
		lat: 40.4406248,
		long: -79.9958864,
	},
	{
		city: 'Providence',
		state: 'RI',
		lat: 41.8239891,
		long: -71.4128343,
	},
	{
		city: 'Charleston',
		state: 'SC',
		lat: 32.7764749,
		long: -79.9310512,
	},
	{
		city: 'Knoxville',
		state: 'TN',
		lat: 35.9606384,
		long: -83.9207392,
	},
	{
		city: 'Memphis',
		state: 'TN',
		lat: 35.1495343,
		long: -90.0489801,
	},
	{
		city: 'Nashville',
		state: 'TN',
		lat: 36.1626638,
		long: -86.7816016,
	},
	{
		city: 'Austin',
		state: 'TX',
		lat: 30.267153,
		long: -97.7430608,
	},
	{
		city: 'Dallas',
		state: 'TX',
		lat: 32.7766642,
		long: -96.7969879,
	},
	{
		city: 'Houston',
		state: 'TX',
		lat: 29.7604267,
		long: -95.3698028,
	},
	{
		city: 'San Antonio',
		state: 'TX',
		lat: 29.4241219,
		long: -98.4936282,
	},
	{
		city: 'Salt Lake City',
		state: 'UT',
		lat: 40.7607793,
		long: -111.8910474,
	},
	{
		city: 'Burlington',
		state: 'VT',
		lat: 44.4758825,
		long: -73.212072,
	},
	{
		city: 'Arlington',
		state: 'VA',
		lat: 38.8799697,
		long: -77.1067698,
	},
	{
		city: 'Richmond',
		state: 'VA',
		lat: 37.5407246,
		long: -77.4360481,
	},
	{
		city: 'Virginia Beach',
		state: 'VA',
		lat: 36.8529263,
		long: -75.977985,
	},
	{
		city: 'Seattle',
		state: 'WA',
		lat: 47.6062095,
		long: -122.3320708,
	},
	{
		city: 'Tacoma',
		state: 'WA',
		lat: 47.2528768,
		long: -122.4442906,
	},
	{
		city: 'Green Bay',
		state: 'WI',
		lat: 44.5133188,
		long: -88.0132958,
	},
	{
		city: 'Madison',
		state: 'WI',
		lat: 43.0730517,
		long: -89.4012302,
	},
	{
		city: 'Milwaukee',
		state: 'WI',
		lat: 43.0389025,
		long: -87.9064736,
	},
	{
		city: 'New York',
		state: 'NY',
		lat: 40.7128,
		long: -74.0061,
	},
	{
		city: 'New York',
		state: 'NY',
		lat: 40.7829,
		long: -73.965,
	},
];

export const JSON_LD_DATA = {
	'@id': 'https://www.unpakt.com',
	'@context': 'https://schema.org',
	'@type': 'MovingCompany',
	name: 'Unpakt',
	sameAs: [
		'https://www.facebook.com/Unpakt/',
		'https://twitter.com/unpakt',
		'https://wikipedia.org/wiki/Unpakt',
		'https://www.instagram.com/unpakt/',
		'https://www.pinterest.com/unpakt/',
	],
	slogan: 'No move day surprises, no hidden fees.',
	priceRange: '$$$',
	address: {
		'@type': 'PostalAddress',
		contactType: 'Customer service',
		addressLocality: 'Bronx',
		addressRegion: 'NY',
		addressCountry: 'US',
		postalCode: '10454',
		streetAddress: '27 Bruckner Blvd suite 214',
	},
	image: [
		'https://api.unpakt.com/client_assets/images/home/facebook-social-homepage.jpg',
	],
	aggregateRating: {
		'@type': 'AggregateRating',
		ratingValue: '4.2',
		ratingCount: '36',
		reviewCount: '36',
		itemReviewed: {
			'@type': 'Service',
			name: 'Unpakt',
		},
	},
	geo: {
		'@type': 'GeoCoordinates',
		latitude: 40.8074051,
		longitude: -73.9305201,
	},
	currenciesAccepted: 'USD',
	paymentAccepted: 'Credit Card, PayPal',
	description:
		'Save time and money! Instantly compare moving quotes and guaranteed prices from the best moving companies online, with real customer reviews.',
	openingHoursSpecification: [
		{
			'@type': 'OpeningHoursSpecification',
			dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
			opens: '09:00:00',
			closes: '18:00:00',
		},
		{
			'@type': 'OpeningHoursSpecification',
			dayOfWeek: ['Saturday', 'Sunday'],
			opens: '09:00',
			closes: '15:00',
		},
	],
	openingHours: ['Mo-Fr 09:00-18:00', 'Sa-Su 09:00-15:00'],
	url: 'https://www.unpakt.com',
	contactPoint: {
		'@type': 'ContactPoint',
		contactType: 'Customer service',
		telephone: '(855) 286-7258',
		email: 'support@unpakt.com',
	},
	telephone: '(855) 286-7258',
};

export const SAME_CITY_DIFF_STATE = ['Kansas City', 'Portland'];
