import { handleActions } from 'redux-actions';
import {
	ADD_DELIVERY_ESTIMATE_OPTION,
	SET_BIDS,
	SET_COMPARE_FILTER,
	SET_COMPARE_LOADING,
	SET_FILTERED_RESULTS,
	SET_PRICING_BREAKDOWN,
	MODAL_LOADING,
	SET_ACTIVE_BID,
	SET_IS_CHANGE_MOVER,
} from '../actionTypes';

const initialState = {
	filter: { value: 1, label: 'PRICE LOW TO HIGH' },
	bids: [],
	activeBid: undefined,
	filtered: [],
	breakdown: {
		pricing: {},
	},
	loading: false,
	showDeliveryEstimate: false,
	modalLoading: false,
	changeMover: false,
};

const compareReducer = handleActions(
	{
		[SET_ACTIVE_BID]: (state, { payload }) => ({
			...state,
			activeBid: payload,
		}),
		[SET_BIDS]: (state, { payload }) => ({
			...state,
			bids: payload,
		}),
		[SET_IS_CHANGE_MOVER]: (state, { payload }) => ({
			...state,
			changeMover: payload,
		}),
		[ADD_DELIVERY_ESTIMATE_OPTION]: (state, { payload }) => ({
			showDeliveryEstimate: payload,
		}),
		[SET_COMPARE_FILTER]: (state, action) => ({
			...state,
			filter: action.payload,
		}),
		[SET_FILTERED_RESULTS]: (state, action) => ({
			...state,
			filtered: action.payload,
		}),
		[SET_PRICING_BREAKDOWN]: (state, action) => ({
			...state,
			breakdown: action.payload,
		}),
		[SET_COMPARE_LOADING]: (state, { payload }) => ({
			...state,
			loading: payload,
		}),
		[MODAL_LOADING]: (state, { payload }) => ({
			...state,
			modalLoading: payload,
		}),
	},
	initialState
);

export const getPricingRows = compare => {
	let pricingRows = [];
	if (
		compare.breakdown.pricing.size_surcharge_cost &&
		compare.breakdown.pricing.size_surcharge_cost !== 0
	) {
		pricingRows.push('size_surcharge_cost');
	}
	if (
		compare.breakdown.pricing.special_handling_cost &&
		compare.breakdown.pricing.special_handling_cost !== 0
	) {
		pricingRows.push('special_handling_cost');
	}
	if (
		compare.breakdown.pricing.storage_cost &&
		compare.breakdown.pricing.storage_cost !== 0
	) {
		pricingRows.push('storage_cost');
	}
	if (
		compare.breakdown.pricing.packing_cost &&
		compare.breakdown.pricing.packing_cost !== 0
	) {
		pricingRows.push('packing_cost');
	}
	if (
		compare.breakdown.pricing.cardboard_cost &&
		compare.breakdown.pricing.cardboard_cost !== 0
	) {
		pricingRows.push('cardboard_cost');
	}
	if (
		compare.breakdown.pricing.surcharge_cubic_feet_cost &&
		compare.breakdown.pricing.surcharge_cubic_feet_cost !== 0
	) {
		pricingRows.push('surcharge_cubic_feet_cost');
	}
	if (
		compare.breakdown.pricing.coi_cost &&
		compare.breakdown.pricing.coi_cost !== 0
	) {
		pricingRows.push('coi_cost');
	}
	if (
		compare.breakdown.pricing.insurance_cost &&
		compare.breakdown.pricing.insurance_cost !== 0
	) {
		pricingRows.push('insurance_cost');
	}
	if (
		compare.breakdown.pricing.total_adjustments &&
		compare.breakdown.pricing.total_adjustments !== 0
	) {
		pricingRows.push('total_adjustments');
	}
	if (
		compare.breakdown.pricing.mover_special_discount &&
		compare.breakdown.pricing.mover_special_discount !== 0
	) {
		pricingRows.push('mover_special_discount');
	}
	if (
		compare.breakdown.pricing.discount_without_social_sharing &&
		compare.breakdown.pricing.discount_without_social_sharing !== 0
	) {
		pricingRows.push('discount_without_social_sharing');
	}
	if (
		compare.breakdown.pricing.facebook_social_discount &&
		compare.breakdown.pricing.facebook_social_discount !== 0
	) {
		pricingRows.push('facebook_social_discount');
	}
	if (
		compare.breakdown.pricing.twitter_social_discount &&
		compare.breakdown.pricing.twitter_social_discount !== 0
	) {
		pricingRows.push('twitter_social_discount');
	}
	return pricingRows;
};

export default compareReducer;
