import React, { Suspense, lazy } from 'react';

const AsyncNavLeft = lazy(() => import('./NavLeft'));
const AsyncNavRight = lazy(() => import('./NavRight'));

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isOpen: false };
	}

	handleServicesDropdownOpen = () => {
		this.setState({ isOpen: true });
	};

	handleServicesDropdownClose = () => {
		this.setState({ isOpen: false });
	};

	render() {
		return (
			<header className="header header-container">
				<nav className="desktop-navbar w-100">
					<Suspense fallback={''}>
						<AsyncNavLeft className="d-sm-none" current={this.props.current} />
					</Suspense>
					<Suspense fallback={''}>
						<AsyncNavRight />
					</Suspense>
				</nav>

				<style jsx>{`
					.header-container {
						display: block;
					}
					.header {
						font-size: 13px;
						width: 100%;
						position: fixed;
						left: 0;
						top: 0;
						-webkit-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.26);
						box-shadow: 0 4px 3px rgba(0, 0, 0, 0.26);
						z-index: 1000;
						background: linear-gradient(
							to bottom,
							#1a3749 0,
							#1a3548 13%,
							#1b364b 14%,
							#193447 42%,
							#1a354a 44%,
							#183346 47%,
							#193248 55%,
							#193344 58%,
							#1a3347 59%,
							#183145 60%,
							#193344 100%
						);
						height: 55px;
						line-height: 55px;
					}
					.desktop-navbar {
						padding-left: 15px;
						height: 55px;
						display: flex;
						justify-content: space-between;
					}
				`}</style>
			</header>
		);
	}
}
