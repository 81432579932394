import {
	GET_CITY_DATA,
	GET_CITY_DATA_V2,
	NETWORK_SUCCESS,
	NETWORK_FAILURE,
	GET_STATE_DATA,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
	setMarketingLoading,
	setCityData,
	setStateData,
} from '../actions/marketing';
import { openModal } from '../actions/modals';

const marketingMiddleware =
	({ dispatch }) =>
	next =>
	action => {
		next(action);
		if (action.type === GET_CITY_DATA) {
			dispatch(setMarketingLoading(true));
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_BASE_API_URL}/api/v3/moving_companies/city`,
					method: 'GET',
					params: {
						state: action.payload.stateId,
						city: action.payload.cityId,
					},
					fromAction: {
						type: GET_CITY_DATA,
					},
				})
			);
		}

		if (action.type === GET_CITY_DATA_V2) {
			dispatch(setMarketingLoading(true));
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_BASE_API_URL}/api/v3/moving_companies/city_v2`,
					method: 'GET',
					params: {
						state: action.payload.stateId,
						city: action.payload.cityId,
					},
					fromAction: {
						type: GET_CITY_DATA_V2,
					},
				})
			);
		}

		if (action.type === GET_STATE_DATA) {
			dispatch(setMarketingLoading(true));
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_BASE_API_URL}/api/v3/moving_companies/list`,
					method: 'GET',
					fromAction: {
						type: GET_STATE_DATA,
					},
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction.type) {
				case GET_CITY_DATA:
					dispatch(setCityData(action.payload.response.data));
					dispatch(setMarketingLoading(false));
					break;
				case GET_CITY_DATA_V2:
					dispatch(setCityData(action.payload.response.data));
					dispatch(setMarketingLoading(false));
					break;
				case GET_STATE_DATA:
					dispatch(setStateData(action.payload.response.data));
					dispatch(setMarketingLoading(false));
					break;
				default:
					break;
			}
		}

		if (action.type === NETWORK_FAILURE) {
			switch (action.meta.originalRequest.fromAction.type) {
				case GET_CITY_DATA:
					dispatch(setCityData(false));
					dispatch(setMarketingLoading(false));
					dispatch(
						openModal({
							name: 'apiError',
							properties: {
								error: 'Something went wrong with fetching the city data!',
							},
						})
					);
					break;
				case GET_CITY_DATA_V2:
					dispatch(setCityData(false));
					dispatch(setMarketingLoading(false));
					dispatch(
						openModal({
							name: 'apiError',
							properties: {
								error: 'Something went wrong with fetching the city data!',
							},
						})
					);
					break;
				default:
					break;
			}
		}
	};

export default marketingMiddleware;
