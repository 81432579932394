import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
history.listen(function (loc) {
	// Don't scroll to top if user presses back
	// - if (loc.action === 'POP' || loc.action === 'REPLACE') is an option
	if (loc.action === 'POP') {
		return;
	}
	// Allow the client to control scroll-to-top using location.state
	if (loc.state && loc.state.scroll !== undefined && !loc.state.scroll) {
		return;
	}
	// 200ms delay hack (for Firefox?)
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 200);
});
export default history;
