import {
	SET_ADDITIONAL_STOPS,
	SET_STORAGE,
	FETCH_MOVERS,
	SET_RECALCULATED_MOVERS,
	SET_CURRENT_MOVEPLAN_ID,
	SET_DUMMY_MOVEPLAN_ID,
	SET_DEFAULT_HOME_SIZE,
	SET_MOVING_FLOW_MODAL,
	SET_MOVE_INTO_STORAGE,
	SET_EDIT_INVENTORY_MODAL,
	GET_CURRENT_MOVEPLAN,
	GET_CURRENT_MOVEPLAN_MODAL,
	SET_CURRENT_PLAN_INFO,
	ADD_BOX_PURCHASE,
	REMOVE_BOX_PURCHASE,
	SET_MOVE_DATE,
	GET_VALID_DATES,
	GET_VALID_DATES_HOMEPAGE,
	UPDATE_MOVE_DATE,
	SET_VALID_DATES,
	UPDATE_MOVE_PLAN,
	UPDATE_MOVE_PLAN_MODAL,
	UPDATE_BOX_PURCHASES,
	UPDATE_PACKING_SERVICES,
	SET_COI_INFO,
	SET_TIME_WINDOW,
	CHECK_VALID_DATES,
	CHECK_MOVER_AVAILABILITY,
	PREPARE_RESCHEDULE_REQUEST,
	SET_RESCHEDULE_REQUEST,
	EMAIL_PLAN,
	GET_EMAIL,
	EMAIL_LEAD,
	ADDRESS_UPDATE,
	REQUEST_INSURANCE,
	SET_MOVEPLAN_INSURANCE,
	CANCEL_MOVE,
	CANCEL_CANCEL,
	INIT_BOX_PURCHASES,
	CLEAR_BOX_PURCHASES,
	CREATE_MOVEPLAN,
	CREATE_MOVEPLAN_MODAL,
	CREATE_MOVEPLAN_FOR_PARTNER,
	SUBMIT_DNA,
	SUBMIT_DNA_MODAL,
	SUBMIT_DNA_AFFILIATE,
	SET_DNA_ERROR,
	SET_MOVEPLAN_LOADING,
	SET_MOVEPLAN_MODAL_LOADING,
	CONTINUE_LAST_PLAN,
	SUBMIT_UNSERVED_EMAIL,
	UPDATE_BOX_PURCHASE_COUNT,
	UPDATE_MOVE_TIME,
	SUBMIT_RESCHEDULE_REQUEST,
	CHANGE_MOVER,
	UNSELECT_MOVER,
	UPDATE_NUM_HOURS,
	UPDATE_NUM_LABORERS,
	UPDATE_TRUCK_SIZE,
	UPDATE_NUM_UPRIGHTS,
	UPDATE_HOURLY_STAIRS,
	UPDATE_NUM_BABY_GRAND,
	UPDATE_NUM_HOURLY_OVERWEIGHT,
	UPDATE_NUM_HOURLY_OVERSIZE,
	SET_HOURLY_RENDER_LOADING,
	REMOVE_HOURLY_FLAG,
	INIT_COMPARE_PAGE,
	SELECT_MOVER_MOVE_PLAN,
} from '../actionTypes';

export const setAdditionalStops = payload => ({
	type: SET_ADDITIONAL_STOPS,
	payload: payload,
});

export const fetchMovers = payload => ({
	type: FETCH_MOVERS,
	payload: payload,
});

export const setStorage = payload => ({
	type: SET_STORAGE,
	payload: payload,
});

export const setRecalculatedMovers = payload => ({
	type: SET_RECALCULATED_MOVERS,
	payload: payload,
});

export const initCompare = mpid => ({
	type: INIT_COMPARE_PAGE,
	payload: mpid,
});

export const selectMoverMovePlan = moverId => ({
	type: SELECT_MOVER_MOVE_PLAN,
	payload: moverId,
});

export const submitDNAModal = (payload, bidId) => {
	return {
		type: SUBMIT_DNA_MODAL,
		payload,
		bidId,
	};
};

export const submitDNA = (payload, bidId) => {
	return {
		type: SUBMIT_DNA,
		payload: payload,
		bidId: bidId,
	};
};

export const submitDNAAffiliate = payload => {
	return {
		type: SUBMIT_DNA_AFFILIATE,
		payload: payload,
	};
};

export const createMovePlan = (params, bidId) => {
	return {
		type: CREATE_MOVEPLAN,
		payload: params,
	};
};

export const createMovePlanModal = (params, bidId) => {
	return {
		type: CREATE_MOVEPLAN_MODAL,
		payload: params,
	};
};

export const createForPartner = params => {
	return {
		type: CREATE_MOVEPLAN_FOR_PARTNER,
		payload: params,
	};
};

export const updateMovePlan = (newParams, meta) => {
	return {
		type: UPDATE_MOVE_PLAN,
		payload: newParams,
		meta: meta,
	};
};

export const updateMovePlanModal = (newParams, meta) => {
	return {
		type: UPDATE_MOVE_PLAN_MODAL,
		payload: newParams,
		meta: meta,
	};
};

export const setCurrentMoveplanID = movePlanId => {
	return {
		type: SET_CURRENT_MOVEPLAN_ID,
		payload: { movePlanId },
	};
};

export const setDefaultHomeSize = payload => {
	return {
		type: SET_DEFAULT_HOME_SIZE,
		payload,
	};
};

export const setDummyMoveplanID = movePlanId => {
	return {
		type: SET_DUMMY_MOVEPLAN_ID,
		payload: { movePlanId },
	};
};

export const setMovingFlowModal = payload => {
	return {
		type: SET_MOVING_FLOW_MODAL,
		payload,
	};
};

export const setMoveInToStorage = payload => {
	return {
		type: SET_MOVE_INTO_STORAGE,
		payload,
	};
};

export const setEditInventoryModal = payload => {
	return {
		type: SET_EDIT_INVENTORY_MODAL,
		payload,
	};
};

export const getCurrentMoveplan = meta => {
	return {
		type: GET_CURRENT_MOVEPLAN,
		meta: !!meta ? meta : {},
	};
};

export const getCurrentMoveplanModal = meta => {
	return {
		type: GET_CURRENT_MOVEPLAN_MODAL,
		meta: !!meta ? meta : {},
	};
};

export const setCurrentPlanInfo = plan => {
	return {
		type: SET_CURRENT_PLAN_INFO,
		payload: { plan },
	};
};

export const addBoxPurchase = boxId => {
	return {
		type: ADD_BOX_PURCHASE,
		payload: boxId,
	};
};

export const removeBoxPurchase = boxId => {
	return {
		type: REMOVE_BOX_PURCHASE,
		payload: boxId,
	};
};

export const updateBoxPurchaseCount = (boxId, count) => {
	return {
		type: UPDATE_BOX_PURCHASE_COUNT,
		payload: { boxId: boxId, count: count },
	};
};

export const updateMoveDate = move_date => {
	return {
		type: UPDATE_MOVE_DATE,
		payload: { move_date },
	};
};

export const updateMoveTime = move_time => {
	return {
		type: UPDATE_MOVE_TIME,
		payload: { move_time },
	};
};

export const setMoveDate = moveDate => {
	return {
		type: SET_MOVE_DATE,
		payload: moveDate,
	};
};

export const getValidDates = () => {
	return {
		type: GET_VALID_DATES,
	};
};

export const getValidDatesHomePage = () => {
	return {
		type: GET_VALID_DATES_HOMEPAGE,
	};
};

export const setValidDates = validDates => {
	return {
		type: SET_VALID_DATES,
		payload: validDates,
	};
};

export const updateBoxPurchases = (boxPurchases, date) => {
	return {
		type: UPDATE_BOX_PURCHASES,
		payload: { date: date, boxPurchases: boxPurchases },
	};
};

export const updatePackingServices = serviceNumber => {
	return {
		type: UPDATE_PACKING_SERVICES,
		payload: serviceNumber,
	};
};

export const updateHourlyTruckSize = truckSize => {
	return {
		type: UPDATE_TRUCK_SIZE,
		payload: truckSize,
	};
};

export const updateHourlyNumHours = hours => {
	return {
		type: UPDATE_NUM_HOURS,
		payload: hours,
	};
};

export const updateHourlyNumLabor = laborers => {
	return {
		type: UPDATE_NUM_LABORERS,
		payload: laborers,
	};
};

export const updateHourlyStairs = stairs => {
	return {
		type: UPDATE_HOURLY_STAIRS,
		payload: stairs,
	};
};

export const updateHourlyNumUprights = uprights => {
	return {
		type: UPDATE_NUM_UPRIGHTS,
		payload: uprights,
	};
};

export const updateHourlyNumBabyGrands = baby_grands => {
	return {
		type: UPDATE_NUM_BABY_GRAND,
		payload: baby_grands,
	};
};

export const updateNumHourlyOverWeight = num_hourly_overweight => {
	return {
		type: UPDATE_NUM_HOURLY_OVERWEIGHT,
		payload: num_hourly_overweight,
	};
};

export const updateNumHourlyOverSize = num_hourly_oversize => {
	return {
		type: UPDATE_NUM_HOURLY_OVERSIZE,
		payload: num_hourly_oversize,
	};
};

export const setCoiInfo = coiInfo => {
	return {
		type: SET_COI_INFO,
		payload: coiInfo,
	};
};

export const setTimeWindow = timeWindow => {
	return {
		type: SET_TIME_WINDOW,
		payload: timeWindow,
	};
};

export const checkValidDates = params => {
	return {
		type: CHECK_VALID_DATES,
		payload: params,
	};
};

export const checkMoverAvailability = params => {
	return {
		type: CHECK_MOVER_AVAILABILITY,
		payload: params,
	};
};

export const prepareRescheduleRequest = params => {
	return {
		type: PREPARE_RESCHEDULE_REQUEST,
		payload: params,
	};
};

export const setRescheduleRequest = params => {
	return {
		type: SET_RESCHEDULE_REQUEST,
		payload: params,
	};
};

export const emailPlan = email => {
	return {
		type: EMAIL_PLAN,
		payload: email,
	};
};

export const getEmail = email => {
	return {
		type: GET_EMAIL,
		payload: email,
	};
};

export const emailLead = email => {
	return {
		type: EMAIL_LEAD,
		payload: email,
	};
};

export const addressUpdate = (addressKey, route) => {
	return {
		type: ADDRESS_UPDATE,
		payload: { addressKey: addressKey, route: route },
	};
};

export const requestInsurance = () => {
	return {
		type: REQUEST_INSURANCE,
	};
};

export const setMoveplanInsurance = bool => {
	return {
		type: SET_MOVEPLAN_INSURANCE,
		payload: bool,
	};
};

export const cancelMove = message => {
	return {
		type: CANCEL_MOVE,
		payload: message,
	};
};

export const initBoxPurchases = inventoryBoxes => {
	return {
		type: INIT_BOX_PURCHASES,
		payload: inventoryBoxes,
	};
};

export const clearBoxPurchases = () => {
	return {
		type: CLEAR_BOX_PURCHASES,
	};
};

export const setMoveplanLoading = bool => {
	return {
		type: SET_MOVEPLAN_LOADING,
		payload: bool,
	};
};

export const setMoveplanModalLoading = bool => {
	return {
		type: SET_MOVEPLAN_MODAL_LOADING,
		payload: bool,
	};
};

export const continueLastPlan = () => {
	return {
		type: CONTINUE_LAST_PLAN,
	};
};

export const setDnaError = error => {
	return {
		type: SET_DNA_ERROR,
		payload: error,
	};
};

export const submitUnservedEmail = email => {
	return {
		type: SUBMIT_UNSERVED_EMAIL,
		payload: email,
	};
};

export const submitRescheduleRequest = params => {
	return {
		type: SUBMIT_RESCHEDULE_REQUEST,
		payload: params,
	};
};

export const changeMover = params => {
	return {
		type: CHANGE_MOVER,
		payload: params,
	};
};

export const unselectMover = () => {
	return {
		type: UNSELECT_MOVER,
	};
};

export const cancelCancel = () => {
	return {
		type: CANCEL_CANCEL,
		payload: true,
	};
};

export const setHourlyRenderLoading = bool => {
	return {
		type: SET_HOURLY_RENDER_LOADING,
		payload: bool,
	};
};

export const removeHourlyFlag = () => {
	return {
		type: REMOVE_HOURLY_FLAG,
	};
};
