import {
	ADD_NOTE,
	APPLY_COUPON,
	DELETE_ADJUSTMENT,
	DELETE_NOTE,
	FINALIZE_PLAN,
	GET_HISTORY,
	GET_NOTES,
	INIT_PLAN,
	INIT_PLAN_FINISHED,
	INIT_PRINT_PLAN,
	INIT_ROUTES,
	SET_ADJUSTMENT_LOADING,
	SET_ADMIN_NOTES,
	SET_EMAIL_ERROR,
	SET_EMAIL_SENT,
	SET_HISTORY,
	SET_HISTORY_UPDATING,
	SET_MOVER_NOTES,
	SET_NOTES_UPDATING,
	SET_NOTIFICATION_DISMISSED,
	SET_PLAN_UPDATING,
	SET_ROUTES,
	SET_SELECTED_TAB_PLAN,
	SET_SHOW_STORAGE,
	TOGGLE_STORAGE_PLAN,
	UPDATE_ADJUSTMENT,
	UPDATE_COUPON,
	GET_ADJUSTMENT_CALC,
	SET_ADJUSTMENT_PAYLOAD,
	MOVE_DAY_ADJUSTMENT,
	SET_MDA_SENDING,
	SET_MDA_STATUS,
} from '../actionTypes';

export const initPlan = movePlanId => ({
	type: INIT_PLAN,
	payload: movePlanId,
});

export const setNotificationDismissed = () => ({
	type: SET_NOTIFICATION_DISMISSED,
});

export const setSelectedTabPlan = key => ({
	type: SET_SELECTED_TAB_PLAN,
	payload: key,
});

export const finalizePlan = () => ({
	type: FINALIZE_PLAN,
});

export const setEmailError = bool => ({
	type: SET_EMAIL_ERROR,
	payload: bool,
});

export const setEmailSent = bool => ({
	type: SET_EMAIL_SENT,
	payload: bool,
});

export const initRoutes = () => ({
	type: INIT_ROUTES,
});

export const setRoutes = routes => ({
	type: SET_ROUTES,
	payload: routes,
});

export const initPlanFinished = () => ({
	type: INIT_PLAN_FINISHED,
});

export const toggleStoragePlan = () => ({
	type: TOGGLE_STORAGE_PLAN,
});

export const setShowStorage = bool => ({
	type: SET_SHOW_STORAGE,
	payload: bool,
});

export const setPlanUpdating = bool => ({
	type: SET_PLAN_UPDATING,
	payload: bool,
});

export const getHistory = () => ({
	type: GET_HISTORY,
});

export const setHistoryUpdating = bool => ({
	type: SET_HISTORY_UPDATING,
	payload: bool,
});

export const setHistory = history => ({
	type: SET_HISTORY,
	payload: history,
});

export const getNotes = type => ({
	type: GET_NOTES,
	payload: type,
});

export const setNotesUpdating = bool => ({
	type: SET_NOTES_UPDATING,
	payload: bool,
});

export const setMoverNotes = notes => ({
	type: SET_MOVER_NOTES,
	payload: notes,
});

export const setAdminNotes = notes => ({
	type: SET_ADMIN_NOTES,
	payload: notes,
});

export const addNote = note => ({
	type: ADD_NOTE,
	payload: note,
});

export const deleteNote = id => ({
	type: DELETE_NOTE,
	payload: id,
});

export const deleteAdjustment = adjustment => ({
	type: DELETE_ADJUSTMENT,
	payload: adjustment,
});

export const setAdjustmentLoading = bool => ({
	type: SET_ADJUSTMENT_LOADING,
	payload: bool,
});

export const updateAdjustment = params => ({
	type: UPDATE_ADJUSTMENT,
	payload: params,
});

export const updateCoupon = params => ({
	type: UPDATE_COUPON,
	payload: params,
});

export const applyCoupon = () => ({
	type: APPLY_COUPON,
});

export const initPrintPlan = () => ({
	type: INIT_PRINT_PLAN,
});

export const getAdjustmentCalc = type => ({
	type: GET_ADJUSTMENT_CALC,
	payload: type,
});

export const setAdjustmentPayload = payload => ({
	type: SET_ADJUSTMENT_PAYLOAD,
	payload: payload,
});

export const moveDayAdjustment = payload => ({
	type: MOVE_DAY_ADJUSTMENT,
	payload: payload,
});

export const setMdaSending = bool => ({
	type: SET_MDA_SENDING,
	payload: bool,
});

export const setMdaStatus = payload => ({
	type: SET_MDA_STATUS,
	payload: payload,
});
