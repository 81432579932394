import { handleActions } from 'redux-actions';
import { SET_ALL_PLANS, SET_CITY_DATA_FOR_HOME_PAGE } from '../actionTypes';
import has from 'lodash-es/has';
import { isAfter } from 'date-fns';

const initialState = {};

const myHomeReducer = handleActions(
	{
		[SET_ALL_PLANS]: (state, { payload }) => ({
			...state,
			userPlans: payload,
		}),
		[SET_CITY_DATA_FOR_HOME_PAGE]: (state, { payload }) => ({
			cityData: payload,
		}),
	},
	initialState
);

export const hasUpcomingJob = userPlans => {
	let now = Date.now();
	if (has(userPlans, 'booked_move_plans')) {
		if (userPlans.booked_move_plans.length > 0) {
			return userPlans.booked_move_plans.map(plan =>
				isAfter(new Date(plan.move_date), now)
			)[0];
		}
	}
	return false;
};

export default myHomeReducer;
